import * as custom from './filters/marketplace-custom-config';
import { defaultMCC, stripeCountryDetails, stripePublishableKey } from '../stripe-config';
import { Conditions } from './filters/condition';
import { SellerImageCategory } from '../types/photos/photos';
import {
  FontConfigSection,
  ListingFlowSection,
  ProgressBarSection,
  StyleType,
} from '../types/shopConfig/shopConfigV2';
import { dressMeasurements } from '../util/constants';

const env = process.env.REACT_APP_ENV;
const dev = process.env.REACT_APP_ENV === 'development';
const test = process.env.REACT_APP_ENV === 'test';

// If you want to change the language, remember to also change the
// locale data and the messages in the app.js file.
const locale = 'en';
const i18n = {
  /*
    0: Sunday
    1: Monday
    ...
    6: Saturday
  */
  firstDayOfWeek: 0,
};

// Should search results be ordered by distance to origin.
// NOTE: If this is set to true add parameter 'origin' to every location in default-location-searches.js
// Without the 'origin' parameter, search will not work correctly
// NOTE: Keyword search and ordering search results by distance can't be used at the same time. You can turn keyword
// search off by changing the keywordFilterConfig parameter active to false in marketplace-custom-config.js
const sortSearchByDistance = false;

// API supports custom processes to be used in booking process.
// We need to specify it when we are initiating a new order
// (or fetching price information by calling 'initiateSpeculative' endpoint).
//
// In a way, 'processAlias' defines which transaction process (or processes)
// this particular web application is able to handle.
const processAlias = 'treet/release-1';

// The transaction line item code for the main unit type in bookings.
//
// Possible values: ['line-item/night', 'line-item/day', 'line-item/units';]
//
// Note 1: This 'bookingUnitType' variable affects only web app.
//         If you are using privileged transitions (which is used by the default process),
//         you also need to configure unit type in API server: server/api-util/lineItems.js
//
// Note 2: Translations will use different translation keys for night, day or unit
//         depending on the value chosen.
const bookingUnitType = 'line-item/units';

// Should the application fetch available time slots (currently defined as
// start and end dates) to be shown on listing page.
const enableAvailability = process.env.REACT_APP_AVAILABILITY_ENABLED === 'false';

// A maximum number of days forwards during which a booking can be made.
// This is limited due to Stripe holding funds up to 90 days from the
// moment they are charged. Also note that available time slots can only
// be fetched for 180 days in the future.
const dayCountAvailableForBooking = 90;

// Listing minimum price in currency sub units, e.g. cents.
// 0 means no restriction to the price
const listingMinimumPriceSubUnits = 0;

// Address information is used in SEO schema for Organization (http://schema.org/PostalAddress)
const addressCountry = 'US';
const addressRegion = 'San Francisco';
const postalCode = '94105';
const streetAddress = '535 Mission St';

// Canonical root url is needed in social media sharing and SEO optimization purposes.
const canonicalRootURL = process.env.REACT_APP_CANONICAL_ROOT_URL;

export const defaultTreetStyles = {
  // Default grays
  gray100: '#292929',
  gray80: '#454545',
  gray60: '#696969',
  gray40: '#A7A7A7',
  gray20: '#D1D1D1',
  gray10: '#F8F8F8',
  gray00: '#FFFFFF',

  // Default reds
  red100: '#601616',
  red80: '#962A2A',
  red60: '#BB4E4E',
  red40: '#E87E7E',
  red20: '#FFA5A5',
  red10: '#FFDCDC',

  // Default greens
  green100: '#273D22',
  green80: '#31622A',
  green60: '#87A982',
  green40: '#B7D1B3',
  green20: '#DAF0D7',
  green10: '#F5FCF4',

  // Default teals
  teal100: '#1E3D3F',
  teal80: '#437073',
  teal10: '#D9F1F2',
};

const defaultStyles = {
  ...defaultTreetStyles,

  landingPageBackgroundTint: '#f4f7fb',

  marketplaceColor: '#000000',
  marketplaceColorLight: '#777777',
  marketplaceColorDark: '#000000',

  // Default colors from marketplace.css
  matterColor: '#4a4a4a',
  matterColorBright: '#fcfcfc',
  matterColorDark: '#000',
  matterColorAnti: '#b2b2b2',
  inputAttentionColor: '#ffaa00',
  inputSuccessColor: '#68b37f',

  heroButtonColor: 'white',
  heroButtonHoverColor: '#505050',
  heroButtonTextHoverColor: 'white',
  heroButtonBorder: 'none',
  heroButtonHoverBorder: 'none',
  heroButtonTextColor: 'black',
  heroButtonMargin: '10px',
  heroContentAlignment: 'center',
  heroSubTitleMaxWidth: '100%',

  buttonColor: '#404040',
  buttonColorHover: '#505050',
  buttonTextColor: 'white',
  buttonBorder: 'none',
  buttonFontSize: '18px',
  buttonFontWeight: '600',
  buttonLetterSpacing: '-0.3px',
  buttonPadding: '14px 24px',
  secondaryTextColor: '#4a4a4a',
  buttonLineHeight: '24px',
  buttonTextTransform: 'none',

  socialIconsColor: '#4a4a4a',
  socialIconsColorHover: '#2f376a',

  borderRadius: '0',
  borderRadiusHalf: '0',

  heroFontSpacing: '0',
  heroFontWeight: '700',
  heroFontColor: '#ffffff',
  heroFontTextTransform: 'none',
  heroFontSizeDesktop: '64px',
  heroFontSizeLarge: '72px',

  topbarLinkFontSizeSmall: '12px',
  topbarLinkFontSizeMedium: '16px',
  topbarLinkFontWeight: '500',
  topbarLinkTextTransform: 'none',
  topbarLinkLetterSpacing: '0',
  topbarIconHeight: '11px',

  h1FontSizeSmall: '30px',
  h1FontSizeMedium: '40px',
  h1FontWeightSmall: '700',
  h1LetterSpacingSmall: '-1px',
  h1LetterSpacingMedium: '-2px',
  h1FontWeightMedium: '600',
  h1TextTransform: 'none',

  h2FontSize: '24px',
  h2LetterSpacing: '-0.3px',
  h2TextTransform: 'none',
  h2FontWeight: '600',

  h3FontSize: '20px',
  h3LetterSpacing: '-0.2px',
  h3TextTransform: 'none',
  h3FontWeightSemiBold: 600,

  h4FontSizeSmall: '12px',
  h4FontSizeMedium: '16px',
  h4TextTransform: 'none',
  h4LetterSpacing: '0',

  pFontSizeSmall: '18px',
  pFontSizeMedium: '20px',
  pLetterSpacing: '-0.1px',
  pTextTransform: 'none',

  backgroundImageUrl: null,
  backgroundImageUrlSmall: null,

  footerImageUrl: null,
  footerImageUrlSmall: null,
  footerLogoHeight: '40px',
  footerLogoMaxWidth: '300px',
  footerLinkTextTransform: 'none',
  footerLinkFontSize: '16px',
  footerTopBorderWidth: '1px',

  organizationDescriptionTextAlign: 'left',
};

/** This determines how we should choose which stock images to use for a listing */
export const CHOOSE_STOCK_IMAGES_OPTIONS = {
  UNHIDE: 'UNHIDE',
  ETTITUDE: 'ETTITUDE',
  ANIAN: 'ANIAN',
  PORTLAND_LEATHER: 'PORTLAND_LEATHER',
  SPELL: 'SPELL',
  DEFAULT: 'DEFAULT',
};

/** The title that is displayed for products */
export const PRODUCT_DISPLAY_OPTIONS = {
  TITLE: 'TITLE',
  COCLICO: 'COCLICO',
  ROUGH_AND_TUMBLE: 'ROUGH_AND_TUMBLE',
  DOPPLE: 'DOPPLE',
  TEEKI_BOUTIQUE: 'TEEKI_BOUTIQUE',
  LAUDE_THE_LABEL: 'LAUDE_THE_LABEL',
  KPOP_EXCHANGE: 'KPOP_EXCHANGE',
  ACE_AND_JIG: 'ACE_AND_JIG',
};

export const IMAGE_CATEGORIES_DEFAULT_CONFIG = {
  [SellerImageCategory.Front]: {
    label: 'Front side of your item',
    required: true,
  },
  [SellerImageCategory.Back]: {
    label: 'Back side of your item',
    required: true,
  },
  [SellerImageCategory.Tag]: {
    label: 'Brand tag',
    required: true,
  },
  [SellerImageCategory.RetailTag]: {
    required: true,
    // Only require this if it's new with tags
    conditionsToShow: [Conditions.NewWithTags],
    label: 'Retail or hang tag',
  },
  [SellerImageCategory.Additional]: {
    label: 'Misc. photos (pics of you wearing the item, any and all quirks, etc.)',
  },
};

export const IMAGE_CATEGORIES_SHOES_DEFAULT_CONFIG = {
  [SellerImageCategory.Top]: {
    label: 'Top of the item',
    required: true,
  },
  [SellerImageCategory.Side]: {
    label: 'Side view of the item',
    required: true,
  },
  [SellerImageCategory.Sole]: {
    label: 'Sole',
    required: true,
  },
  [SellerImageCategory.Tag]: {
    // Only show this option for the conditions listed here
    conditionsToShow: [Conditions.NewWithTags],
    label: 'Box with shoes inside',
    required: true,
  },
  [SellerImageCategory.Additional]: {
    label: 'Misc. Photos (pics of you wearing the shoes, any and all quirks, etc.)',
  },
};

export const LANDING_PAGE_SECTION = {
  HERO: 'HERO',
  ABOUT: 'ABOUT',
  ABOUT_OVERLAP: 'ABOUT_OVERLAP',
  CATEGORIES: 'CATEGORIES',
  HOW_IT_WORKS: 'HOW_IT_WORKS',
  ABOUT_TREET: 'ABOUT_TREET',
  FOOTER: 'FOOTER',
};

export const HOW_IT_WORKS_TYPES = {
  DEFAULT: 'DEFAULT',
  SWIM: 'SWIM',
};

// Keep in sync with server/utils/shopUtils.js
export const POWERED_BY_TREET_TYPES = {
  ICON: 'ICON',
  NONE: 'NONE',
};

export const ABOUT_TREET_TITLE_TYPES = {
  TEXT: 'TEXT',
  ICON: 'ICON',
};

export const ABOUT_SECTION_LAYOUT = {
  DEFAULT: 'DEFAULT',
  // Overlap about section layout is used by lighthouse
  OVERLAP: 'OVERLAP',
};

export const FEATURED_LISTING_IMAGE_TYPE = {
  USER_UPLOADED: 'USER_UPLOADED',
  STOCK: 'STOCK',
};

export const FEATURED_LISTING_IMAGE_TYPE_TAB_NAMES = {
  [FEATURED_LISTING_IMAGE_TYPE.STOCK]: 'STOCK',
  [FEATURED_LISTING_IMAGE_TYPE.USER_UPLOADED]: 'SELLER',
};

export const defaultMarketplaceItemDetailsFormSections = [
  ListingFlowSection.VariantOptions,
  ListingFlowSection.Condition,
  ListingFlowSection.Quirks,
  ListingFlowSection.Description,
  ListingFlowSection.StockPhotos,
  ListingFlowSection.Photos,
  ListingFlowSection.Price,
  ListingFlowSection.InternalBrandNotes,
];

export const defaultProgressBarSections = [
  ProgressBarSection.FindItem,
  ProgressBarSection.ItemDetails,
  ProgressBarSection.Condition,
  ProgressBarSection.Photos,
  ProgressBarSection.Price,
  ProgressBarSection.Acknowledgement,
];

const defaultConfig = {
  allowUserGeneratedTitle: false,

  // Most of the time, shops have a separate product per color so we don't need to show the color
  showColorOnListingCard: false,
  // If we should show the stock images at all

  // Order of the layout on the landing page
  // NOTE: if you add a new section here, you'll need to add it to other places
  // we define a custom landingPageLayoutOrder as necessary
  landingPageLayoutOrder: [
    LANDING_PAGE_SECTION.HERO,
    LANDING_PAGE_SECTION.ABOUT,
    LANDING_PAGE_SECTION.CATEGORIES,
    LANDING_PAGE_SECTION.HOW_IT_WORKS,
    LANDING_PAGE_SECTION.ABOUT_TREET,
    LANDING_PAGE_SECTION.FOOTER,
  ],

  // Categories that show up in the top navbar
  homepageCategories: [],

  shouldShowOtherItemsInSearch: true,

  shouldUseStaticSizes: true,

  styleType: StyleType.Default,

  // Mappings from font section to font type if different from default
  fontConfig: {
    [FontConfigSection.AboutPageFAQTitle]: 'body1',
    [FontConfigSection.AboutPageGeneralInfoTitle]: 'h1',
    [FontConfigSection.FieldLabel]: 'body1',
  },
};

// For every actual store we launch, we need to make sure we add the store to config.test.js and make sure we are not using the demo store config
// When adding new fields, we should add it to
const shopConfigs = {
  treet: {
    ...defaultConfig,
    styles: defaultStyles,
  },
  fortress: {
    ...defaultConfig,
    copy: {},
    landingPageLayoutOrder: [
      LANDING_PAGE_SECTION.HERO,
      LANDING_PAGE_SECTION.ABOUT,
      LANDING_PAGE_SECTION.HOW_IT_WORKS,
      LANDING_PAGE_SECTION.ABOUT_TREET,
      LANDING_PAGE_SECTION.FOOTER,
    ],
    styles: {
      ...defaultStyles,
      fontFamily: 'Quicksand',
      heroFontFamily: 'TenorSans',
      heroSubTitleFontFamily: 'Quicksand',
      backgroundImageUrl:
        "linear-gradient(-46deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3)), url('/static/heroImages/fortress.jpg')",
      backgroundImageUrlSmall:
        "linear-gradient(-46deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3)), url('/static/heroImages/fortress-small.jpg')",
      footerImageUrl:
        "linear-gradient(-46deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3)), url('/static/footerImages/fortress.jpg')",
      footerImageUrlSmall:
        "linear-gradient(-46deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3)), url('/static/footerImages/fortress.jpg')",
      buttonColor: '#212121',
      buttonColorHover: '#212121',
      buttonTextColor: '#ffffff',
      heroButtonColor: '#212121',
      heroButtonHoverColor: '#212121',
      heroButtonTextColor: '#ffffff',
      landingPageBackgroundTint: '#F9F6F0',
      buttonTextTransform: 'lowercase',
      buttonLetterSpacing: '3px',
      marketplaceColor: '#000000',
      marketplaceColorLight: '#777777',
      marketplaceColorDark: '#000000',
    },
  },
  pistollake: {
    ...defaultConfig,
    chooseStockImagesOption: CHOOSE_STOCK_IMAGES_OPTIONS.DEFAULT,
    copy: {},
    landingPageLayoutOrder: [
      LANDING_PAGE_SECTION.HERO,
      LANDING_PAGE_SECTION.ABOUT,
      LANDING_PAGE_SECTION.HOW_IT_WORKS,
      LANDING_PAGE_SECTION.ABOUT_TREET,
      LANDING_PAGE_SECTION.FOOTER,
    ],
    styles: {
      ...defaultStyles,
      fontFamily: 'Garamond',
      heroFontFamily: 'futura',
      h1FontFamily: 'futura',
      h1TextTransform: 'uppercase',
      h1FontSizeSmall: '32px',
      h1FontSizeMedium: '32px',
      h1LetterSpacingSmall: '2px',
      h1LetterSpacingMedium: '2px',
      backgroundImageUrl:
        "linear-gradient(-46deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3)), url('/static/heroImages/pistollake.jpg')",
      backgroundImageUrlSmall:
        "linear-gradient(-46deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3)), url('/static/heroImages/pistollake.jpg')",
      footerImageUrl:
        "linear-gradient(-46deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3)), url('/static/footerImages/pistollake.jpg')",
      footerImageUrlSmall:
        "linear-gradient(-46deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3)), url('/static/footerImages/pistollake.jpg')",
      heroButtonColor: '#ffffff',
      heroButtonHoverColor: '#ffffff',
      heroButtonTextColor: '#3D3D3D',
      heroButtonTextHoverColor: '#3D3D3D',
      heroFontTextTransform: 'uppercase',
      heroFontSpacing: '2px',
      heroFontSizeLarge: '45px',
      heroFontSizeDesktop: '45px',
      heroFontWeight: '700',
      buttonFontFamily: 'futurabook',
      buttonFontSize: '16px',
      buttonFontWeight: '600',
      buttonColor: '#3D3D3D',
      buttonColorHover: '#3D3D3D',
      buttonTextColor: 'white',
      landingPageBackgroundTint: '#f3f3f3',
      buttonTextTransform: 'uppercase',
      buttonLetterSpacing: '3px',
      marketplaceColor: '#000000',
      marketplaceColorLight: '#777777',
      marketplaceColorDark: '#000000',
    },
  },
  minnow: {
    ...defaultConfig,
    chooseStockImagesOption: CHOOSE_STOCK_IMAGES_OPTIONS.DEFAULT,
    homepageCategories: [
      {
        searchQuery: 'pub_tags=girls',
        imagePath: '/static/categoryImages/minnow-girls.jpg',
        label: 'girls',
      },
      {
        searchQuery: 'pub_tags=boys',
        imagePath: '/static/categoryImages/minnow-boys.jpg',
        label: 'boys',
      },
    ],
    copy: {
      footerDescription: 'Be the first to know about new additions to the minnow archive',
    },
    landingPageLayoutOrder: [
      LANDING_PAGE_SECTION.HERO,
      LANDING_PAGE_SECTION.ABOUT,
      LANDING_PAGE_SECTION.CATEGORIES,
      LANDING_PAGE_SECTION.HOW_IT_WORKS,
      LANDING_PAGE_SECTION.ABOUT_TREET,
      LANDING_PAGE_SECTION.FOOTER,
    ],
    styles: {
      ...defaultStyles,
      fontFamily: 'Brandon',
      heroFontFamily: 'Brandon',
      h1FontFamily: 'Brandon',
      inputAttentionColor: '#000000',

      topbarLinkTextTransform: 'lowercase',
      topbarLinkLetterSpacing: 'normal',

      inputSuccessColor: '#000000',
      backgroundImageUrl:
        "linear-gradient(-46deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3)), url('/static/heroImages/minnow.jpg')",
      backgroundImageUrlSmall:
        "linear-gradient(-46deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3)), url('/static/heroImages/minnow.jpg')",
      footerImageUrl:
        "linear-gradient(-46deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3)), url('/static/footerImages/minnow.jpg')",
      footerImageUrlSmall:
        "linear-gradient(-46deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3)), url('/static/footerImages/minnow.jpg')",
      buttonColor: '#bcdfe8',
      buttonColorHover: '#bcdfe8',
      buttonTextColor: '#ffffff',
      heroFontWeight: '600',
      heroButtonColor: '#bcdfe8',
      heroButtonHoverColor: '#bcdfe8',
      heroButtonTextColor: '#ffffff',
      landingPageBackgroundTint: '#bcdfe8',
      buttonTextTransform: 'lowercase',
      buttonLetterSpacing: '1px',
      marketplaceColor: '#000000',
      marketplaceColorLight: '#777777',
      marketplaceColorDark: '#000000',
      h1TextTransform: 'lowercase',
      h1LetterSpacingSmall: 'normal',
      h1LetterSpacingMedium: 'normal',

      h2TextTransform: 'lowercase',
      h2LetterSpacing: 'normal',

      h3TextTransform: 'lowercase',
      h3LetterSpacing: 'normal',

      h4TextTransform: 'lowercase',
      h4LetterSpacing: 'normal',
      h4FontSizeSmall: '16px',
      h4FontSizeMedium: '18px',

      pTextTransform: 'lowercase',
      pLetterSpacing: 'normal',
    },
  },
  firebird: {
    ...defaultConfig,
    landingPageLayoutOrder: [
      LANDING_PAGE_SECTION.HERO,
      LANDING_PAGE_SECTION.ABOUT,
      LANDING_PAGE_SECTION.HOW_IT_WORKS,
      LANDING_PAGE_SECTION.ABOUT_TREET,
      LANDING_PAGE_SECTION.FOOTER,
    ],
    copy: {},
    styles: {
      ...defaultStyles,
      backgroundImageUrl:
        "linear-gradient(-46deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3)), url('/static/heroImages/firebird.jpg')",
      backgroundImageUrlSmall:
        "linear-gradient(-46deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3)), url('/static/heroImages/firebird.jpg')",
      footerImageUrl:
        "linear-gradient(-46deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3)), url('/static/footerImages/firebird.jpg')",
      footerImageUrlSmall:
        "linear-gradient(-46deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3)), url('/static/footerImages/firebird.jpg')",

      fontFamily: "Neuzeits, 'sofiapro', Helvetica",
      h1FontFamily: "Neuzeits, 'sofiapro', Helvetica",
      heroFontFamily: "Neuzeits, 'sofiapro', Helvetica",

      topbarLinkFontSizeMedium: '13px',
      topbarLinkFontSizeSmall: '13px',
      topbarLinkLetterSpacing: '2px',
      topbarLinkTextTransform: 'uppercase',

      h1FontSizeMedium: '28px',
      h1FontSizeSmall: '21px',
      h1LetterSpacingSmall: '0.5px',
      h1LetterSpacingMedium: '0.5px',

      marketplaceColor: '#000000',
      matterColor: '#000000',

      buttonColor: '#000000',
      buttonColorHover: '#000000',
      buttonTextColor: '#FFFFFF',
      buttonLetterSpacing: '3px',
      buttonTextTransform: 'uppercase',
      buttonFontSize: '13px',
      buttonFontWeight: '700',

      heroButtonColor: '#000000',
      heroButtonHoverColor: '#000000',
      heroButtonTextColor: '#FFFFFF',
      heroFontWeight: '700',
    },
  },
  ponto: {
    ...defaultConfig,
    styles: {
      ...defaultStyles,
      fontFamily: "soehne-breit, 'sofiapro', Helvetica",
      h1FontFamily: "soehne-breit, 'sofiapro', Helvetica",
      heroFontFamily: "soehne-breit, 'sofiapro', Helvetica",

      marketplaceColor: '#000000',
      marketplaceColorLight: 'rgba(0, 0, 0, 0.5)',
      marketplaceColorDark: '#000000',
      matterColor: '#000000',

      // Ponto descriptions include <h2>s that we want to override
      h2FontWeight: '500',
    },
    // These fields are overriden in V2
    copy: {},
  },
  maaji: {
    ...defaultConfig,
    styles: {
      ...defaultStyles,

      fontFamily: "sans-serif, 'sofiapro', Helvetica",
      h1FontFamily: "Mier, 'sofiapro', Helvetica",
      heroFontFamily: "Mier, 'sofiapro', Helvetica",

      borderRadius: '2px',
      borderRadiusHalf: '1px',

      marketplaceColor: '#000000',
      marketplaceColorLight: '#1a1a1a',
      marketplaceColorDark: '#000000',
      matterColor: '#000000',
    },
    // These fields are overriden in V2
    copy: {},
  },
  koral: {
    ...defaultConfig,
    styles: {
      ...defaultStyles,
      fontFamily: "futura, 'sofiapro', Helvetica",
      h1FontFamily: "futura, 'sofiapro', Helvetica",
      heroFontFamily: "futura, 'sofiapro', Helvetica",

      marketplaceColor: '#000000',
      marketplaceColorLight: '#1a1a1a',
      marketplaceColorDark: '#000000',
      matterColor: '#000000',
    },
    // These fields are overriden in V2
    copy: {},
  },
  redopple: {
    ...defaultConfig,
    styles: {
      ...defaultStyles,
      fontFamily: "soehne-breit, 'sofiapro', Helvetica",
      h1FontFamily: "soehne-breit, 'sofiapro', Helvetica",
      heroFontFamily: "soehne-breit, 'sofiapro', Helvetica",

      marketplaceColor: '#000000',
      marketplaceColorLight: '#1a1a1a',
      marketplaceColorDark: '#000000',
      matterColor: '#000000',
    },
    // These fields are overriden in V2
    copy: {},
  },
  theheygang: {
    ...defaultConfig,
    styles: {
      ...defaultStyles,

      fontFamily: "Arial, 'sofiapro', Helvetica",
      h1FontFamily: "Arial, 'sofiapro', Helvetica",
      heroFontFamily: "Arial, 'sofiapro', Helvetica",

      marketplaceColor: '#F2CA4B',
      marketplaceColorLight: '#F2CA4B',
      marketplaceColorDark: '#5A7246',
      matterColor: '#000000',
    },
    // These fields are overriden in V2
    copy: {},
  },
  nooworks: {
    ...defaultConfig,
    // Nooworks categories aren't very helpful for finding items
    landingPageLayoutOrder: [
      LANDING_PAGE_SECTION.HERO,
      LANDING_PAGE_SECTION.ABOUT,
      LANDING_PAGE_SECTION.HOW_IT_WORKS,
      LANDING_PAGE_SECTION.ABOUT_TREET,
      LANDING_PAGE_SECTION.FOOTER,
    ],
    copy: {},
    styles: {
      ...defaultStyles,
      backgroundImageUrl:
        "linear-gradient(-46deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3)), url('/static/heroImages/nooworks.jpg')",
      backgroundImageUrlSmall:
        "linear-gradient(-46deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3)), url('/static/heroImages/nooworks.jpg')",
      footerImageUrl:
        "linear-gradient(-46deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3)), url('/static/footerImages/nooworks.jpg')",
      footerImageUrlSmall:
        "linear-gradient(-46deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3)), url('/static/footerImages/nooworks.jpg')",

      fontFamily: "AvenirNext, 'sofiapro', Helvetica",
      h1FontFamily: "AvenirNext, 'sofiapro', Helvetica",
      heroFontFamily: "AvenirNext, 'sofiapro', Helvetica",

      topbarLinkLetterSpacing: '1px',

      h1FontSizeMedium: '32px',
      h1FontSizeSmall: '28px',
      h1FontWeightMedium: '600',
      h1FontWeightSmall: '600',
      h3FontSize: '14px',

      marketplaceColor: '#000000',
      matterColor: '#000000',

      buttonColor: '#F5CD2A',
      buttonColorHover: '#F5CD2A',
      buttonTextColor: '#000000',

      heroButtonColor: '#000000',
      heroButtonHoverColor: '#F5CD2A',
      heroButtonTextColor: '#FFFFFF',
      heroFontWeight: '600',
    },
  },
  unhide: {
    ...defaultConfig,
    chooseStockImagesOption: CHOOSE_STOCK_IMAGES_OPTIONS.UNHIDE,
    // Unhide only has one category
    copy: {},
    landingPageLayoutOrder: [
      LANDING_PAGE_SECTION.HERO,
      LANDING_PAGE_SECTION.ABOUT,
      LANDING_PAGE_SECTION.HOW_IT_WORKS,
      LANDING_PAGE_SECTION.ABOUT_TREET,
      LANDING_PAGE_SECTION.FOOTER,
    ],
    // ratio for search results page
    styles: {
      ...defaultStyles,
      backgroundImageUrl:
        "linear-gradient(-46deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3)), url('/static/heroImages/unhide.jpg')",
      backgroundImageUrlSmall:
        "linear-gradient(-46deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3)), url('/static/heroImages/unhide-small.jpg')",
      buttonColor: '#020e43',
      buttonColorHover: '#343e68',
      buttonTextColor: 'white',
      footerImageUrl:
        "linear-gradient(-46deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3)), url('/static/footerImages/unhide.jpg')",
      footerImageUrlSmall:
        "linear-gradient(-46deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3)), url('/static/footerImages/unhide.jpg')",
      heroButtonColor: '#020e43',
      heroButtonHoverColor: '#343e68',
      heroButtonTextColor: 'white',
      borderRadius: '8px',
      borderRadiusHalf: '4px',
      landingPageBackgroundTint: '#fefaf5',
      fontFamily: 'Garamond',
      heroFontFamily: 'adonis',
      h1FontFamily: 'Garamond',
      buttonLetterSpacing: '0px',
      h1LetterSpacingSmall: '0px',
      h1LetterSpacingMedium: '0px',
      h2LetterSpacing: '0px',
      h3LetterSpacing: '0px',
      h4LetterSpacing: '0px',
      pLetterSpacing: '0px',
      matterColor: '#020e43',
    },
  },
  altarpdx: {
    ...defaultConfig,
    chooseStockImagesOption: CHOOSE_STOCK_IMAGES_OPTIONS.DEFAULT,
    // THIS IS REDUNDANT. either use block tags or the one above. the one above isn't going to work for blocking products from email/order history.
    copy: {},
    // This will find whatever the highest value is between these 2 and use it as the min value
    landingPageLayoutOrder: [
      LANDING_PAGE_SECTION.HERO,
      LANDING_PAGE_SECTION.ABOUT,
      LANDING_PAGE_SECTION.HOW_IT_WORKS,
      LANDING_PAGE_SECTION.ABOUT_TREET,
      LANDING_PAGE_SECTION.FOOTER,
    ],
    styles: {
      ...defaultStyles,
      borderRadius: '8px',
      borderRadiusHalf: '4px',
      backgroundImageUrl:
        "linear-gradient(-46deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3)), url('/static/heroImages/altarpdx.jpg')",
      backgroundImageUrlSmall:
        "linear-gradient(-46deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3)), url('/static/heroImages/altarpdx.jpg')",
      buttonColor: '#C0978A',
      buttonColorHover: '#c6a195',
      buttonTextColor: 'white',
      footerImageUrl:
        "linear-gradient(-46deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3)), url('/static/footerImages/altar.jpg')",
      footerImageUrlSmall:
        "linear-gradient(-46deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3)), url('/static/footerImages/altar.jpg')",

      heroButtonColor: '#C0978A',
      heroButtonHoverColor: '#c6a195',
      heroButtonTextColor: 'black',
      landingPageBackgroundTint: '#dec9c3',
      // color that is on buttons/ etc.
      secondaryTextColor: '#34495e',
    },
  },
  boyish: {
    ...defaultConfig,
    copy: {},
    homepageCategories: [
      {
        imagePath: '/static/categoryImages/boyish-shorts.jpg',
        label: 'Shorts',
        searchQuery: 'pub_category=shorts',
      },
      {
        imagePath: '/static/categoryImages/boyish-jeans.jpg',
        label: 'Jeans',
        searchQuery: 'pub_category=jeans',
      },
    ],
    styles: {
      ...defaultStyles,
      // color that is on the blocks/ etc.
      // secondaryTextColor: 'white',
      backgroundImageUrl:
        "linear-gradient(-46deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3)), url('/static/heroImages/boyish.jpg')",
      backgroundImageUrlSmall:
        "linear-gradient(-46deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3)), url('/static/heroImages/boyish-small.jpg')",
      buttonColor: '#5c5c5c',
      buttonColorHover: '#505050',
      buttonFontSize: '14px',
      buttonLetterSpacing: '.2em',
      buttonTextColor: 'white',
      buttonTextTransform: 'uppercase',
      fontFamily: "Karla, 'sofiapro', Helvetica",
      footerImageUrl:
        "linear-gradient(-46deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3)), url('/static/footerImages/boyish.jpg')",
      footerImageUrlSmall:
        "linear-gradient(-46deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3)), url('/static/footerImages/boyish.jpg')",
      h1FontFamily: 'quasimoda',
      h1FontSizeMedium: '22px',
      h1FontSizeSmall: '20px',
      h1FontWeightMedium: '400',
      h1FontWeightSmall: '400',
      h1TextTransform: 'uppercase',
      h1LetterSpacingSmall: '0',
      h1LetterSpacingMedium: '0.2em',
      h2FontSize: '15px',
      h2LetterSpacing: '0.2em',
      h2TextTransform: 'uppercase',
      h2FontWeight: '400',
      h3FontSize: '18px',
      h3LetterSpacing: '0',
      h3FontWeightSemiBold: '400',
      h4FontSizeMedium: '14px',
      h4TextTransform: 'uppercase',
      pFontSizeSmall: '16px',
      pFontSizeMedium: '18px',
      topbarLinkFontSizeMedium: '14px',
      topbarLinkTextTransform: 'uppercase',
      topbarLinkLetterSpacing: '0.2em',
      topbarLinkFontWeight: '400',

      // headerFontFamily:
      // This is treets color
      // marketplaceColor: '#2f376a',
      // marketplaceColorLight: '#dae0e4',
      // marketplaceColorDark: '#ffb8eb8',
      matterColor: '#5b5b5b',
      heroButtonColor: 'white',
      heroButtonHoverColor: '#5c5c5c',
      heroButtonTextColor: 'black',
      heroFontFamily: 'romana',
      heroFontSpacing: '2.3px',
      heroFontWeight: '400',
    },
  },
  coclico: {
    ...defaultConfig,
    // THIS IS REDUNDANT. either use block tags or the one above. the one above isn't going to work for blocking products from email/order history.
    copy: {},
    landingPageLayoutOrder: [
      LANDING_PAGE_SECTION.HERO,
      LANDING_PAGE_SECTION.ABOUT,
      LANDING_PAGE_SECTION.HOW_IT_WORKS,
      LANDING_PAGE_SECTION.ABOUT_TREET,
      LANDING_PAGE_SECTION.FOOTER,
    ],
    styles: {
      ...defaultStyles,
      backgroundImageUrl:
        "linear-gradient(-46deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3)), url('/static/heroImages/coclico.jpg')",
      backgroundImageUrlSmall:
        "linear-gradient(-46deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3)), url('/static/heroImages/coclico-small.jpg')",
      buttonColor: '#404040',
      buttonColorHover: '#505050',
      buttonTextColor: 'white',
      footerImageUrl:
        "linear-gradient(-46deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3)), url('/static/footerImages/coclico.jpg')",
      footerImageUrlSmall:
        "linear-gradient(-46deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3)), url('/static/footerImage/coclico.jpg')",

      heroButtonColor: 'white',
      heroButtonHoverColor: '#505050',
      heroButtonTextColor: 'black',
      // marketplaceColor: '#ecf0f1',
      // marketplaceColorLight: '#dae0e4',
      // marketplaceColorDark: '#ffb8eb8',
      landingPageBackgroundTint: '#EEEEEE',
      // color that is on buttons/ etc.
      secondaryTextColor: '#34495e',
    },
  },
  'epoque-evolution': {
    ...defaultConfig,
    copy: {},
    homepageCategories: [
      {
        searchQuery: 'pub_category=tops',
        imagePath: '/static/categoryImages/epoque-tops.jpg',
        label: 'tops',
      },
      {
        searchQuery: 'pub_category=bottoms',
        imagePath: '/static/categoryImages/epoque-pants.jpg',
        label: 'bottoms',
      },
    ],
    showColorOnListingCard: true,
    styles: {
      ...defaultStyles,
      backgroundImageUrl:
        "linear-gradient(-46deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3)), url('/static/heroImages/epoque-evolution.jpg')",
      backgroundImageUrlSmall:
        "linear-gradient(-46deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3)), url('/static/heroImages/epoque-evolution-small.jpg')",
      buttonColor: 'black',
      buttonColorHover: '#474747',
      buttonTextColor: 'white',
      footerImageUrl:
        "linear-gradient(-46deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3)), url('/static/footerImages/epoque-evolution.jpg')",
      footerImageUrlSmall:
        "linear-gradient(-46deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3)), url('/static/footerImages/epoque-evolution-small.jpg')",

      heroButtonColor: 'black',
      heroButtonHoverColor: '#474747',
      heroButtonTextColor: 'white',
      // heroFontColor: 'black',
      // marketplaceColor: '#ecf0f1',
      // marketplaceColorLight: '#dae0e4',
      // marketplaceColorDark: '#ffb8eb8',
      landingPageBackgroundTint: '#f5eee8',
      fontFamily: `'Montserrat', sans-serif`,
      heroFontFamily: `'Montserrat', sans-serif`,
      h1FontFamily: `'Montserrat', sans-serif`,
      // color that is on buttons/ etc.
      secondaryTextColor: '#34495e',
      heroFontSpacing: '3px',
      h1FontSizeSmall: '24px',
      h1FontSizeMedium: '28px',
      h1TextTransform: 'uppercase',
      h1LetterSpacingSmall: '2px',
      h1LetterSpacingMedium: '2px',
      h2FontSize: '20px',
      h2LetterSpacing: '0px',
      h2TextTransform: 'uppercase',
      h3FontSize: '19px',
      h3LetterSpacing: '0px',
      h3TextTransform: 'lowercase',
      h4FontSizeMedium: '16px',
      h4TextTransform: 'lowercase',
      topbarLinkFontSizeMedium: '16px',
      topbarLinkTextTransform: 'lowercase',
    },
  },
  marketplace: {
    ...defaultConfig,
    styles: {
      ...defaultStyles,

      fontFamily: "AkzidenzGrotesk, 'sofiapro', Helvetica",
      h1FontFamily: "AkzidenzGrotesk, 'sofiapro', Helvetica",
      heroFontFamily: "AkzidenzGrotesk, 'sofiapro', Helvetica",

      marketplaceColor: '#000000',
      marketplaceColorLight: '#777777',
      marketplaceColorDark: '#000000',
      matterColor: '#000000',

      // TODO (sonia-y|TREET-1110): Change button styling to be dynamic
      buttonColor: 'black',
      buttonColorHover: 'black',
      buttonTextColor: 'white',
      buttonTextColorHover: 'white',
      buttonBorder: '1px solid black',

      heroButtonColor: 'rgba(0, 0, 0, 0)',
      heroButtonBorder: '1px solid white',
      heroButtonHoverBorder: '1px solid white',
      heroButtonTextColor: 'white',
      heroButtonHoverColor: 'rgba(0, 0, 0, 0)',
      heroButtonTextHoverColor: 'white',
    },
    // These fields are overriden in V2
    copy: {},
  },
  poplinen: {
    ...defaultConfig,
    copy: {},
    landingPageLayoutOrder: [
      LANDING_PAGE_SECTION.HERO,
      LANDING_PAGE_SECTION.ABOUT,
      LANDING_PAGE_SECTION.HOW_IT_WORKS,
      LANDING_PAGE_SECTION.ABOUT_TREET,
      LANDING_PAGE_SECTION.FOOTER,
    ],
    styles: {
      ...defaultStyles,
      backgroundImageUrl: "url('/static/heroImages/poplinen.jpg')",
      backgroundImageUrlSmall: "url('/static/heroImages/poplinen-small.jpg')",
      buttonColor: '#10354E',
      buttonColorHover: '#27495f',
      buttonTextColor: 'white',
      fontFamily: 'futura',
      heroButtonColor: '#B58051',
      heroButtonHoverColor: '#c39973',
      heroButtonTextColor: 'white',
      heroFontFamily: 'freight',
      heroFontSpacing: '2px',
      landingPageBackgroundTint: '#FFF3E9',
      buttonLetterSpacing: '2px',
      h1FontFamily: 'futura',
      h1LetterSpacingSmall: '0px',
      h1LetterSpacingMedium: '0px',
      h2LetterSpacing: '0px',
      h3LetterSpacing: '0px',
    },
  },
  sozy: {
    ...defaultConfig,
    copy: {},
    landingPageLayoutOrder: [
      LANDING_PAGE_SECTION.HERO,
      LANDING_PAGE_SECTION.ABOUT,
      LANDING_PAGE_SECTION.HOW_IT_WORKS,
      LANDING_PAGE_SECTION.ABOUT_TREET,
      LANDING_PAGE_SECTION.FOOTER,
    ],
    styles: {
      ...defaultStyles,
      marketplaceColor: '#595959',
      marketplaceColorLight: '#979797',
      marketplaceColorDark: '#3d3d3d',
      matterColor: '#5a5959',

      socialIconsColor: '#000000',
      socialIconsColorHover: '#000000',

      fontFamily: "Brandon, 'sofiapro', Helvetica",
      h1FontFamily: "Brandon, 'sofiapro', Helvetica",
      heroFontFamily: "Brandon, 'sofiapro', Helvetica",
      heroFontWeight: '700',

      topbarLinkFontSizeMedium: '12px',
      topbarLinkFontWeight: '700',
      topbarLinkTextTransform: 'uppercase',
      topbarLinkLetterSpacing: '0.05em',

      backgroundImageUrl:
        "linear-gradient(-46deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3)), url('/static/heroImages/sozy.jpg')",
      backgroundImageUrlSmall:
        "linear-gradient(-46deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3)), url('/static/heroImages/sozy.jpg')",
      buttonColor: '#3d3d3d',
      buttonColorHover: '#5a5959',
      buttonTextColor: 'white',
      buttonFontSize: '15px',
      buttonFontWeight: '700',
      buttonLetterSpacing: '0.05em',
      buttonLineHeight: '20px',
      buttonTextTransform: 'uppercase',
      h1LetterSpacingSmall: '0px',
      h1LetterSpacingMedium: '0px',
      h1FontSizeMedium: '30px',
      h1FontSizeSmall: '24px',
      h1TextTransform: 'uppercase',
      h2FontSize: '22px',
      h2TextTransform: 'uppercase',
      h3FontSize: '18px',
      h4FontSizeSmall: '12px',
      h4FontSizeMedium: '14px',
      h4TextTransform: 'uppercase',
      pFontSizeSmall: '16px',
      pFontSizeMedium: '18px',
      footerImageUrl:
        "linear-gradient(-46deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3)), url('/static/footerImages/sozy.jpg')",
      footerImageUrlSmall:
        "linear-gradient(-46deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3)), url('/static/footerImages/sozy.jpg')",
      heroButtonColor: '#c56d6d',
      heroButtonHoverColor: '#d18b8c',
      heroButtonTextColor: 'white',
      heroButtonTextHoverColor: 'white',
      landingPageBackgroundTint: '#fff8f5',
    },
  },
  wolven: {
    ...defaultConfig,
    copy: {
      footerOrganizationDescription:
        'Wolven Pre-Loved: Where worn clothes go for a second chance at love',
    },
    homepageCategories: [
      {
        searchQuery: 'pub_categories=tops',
        imagePath: '/static/categoryImages/wolven-tops.jpg',
        label: 'Tops',
      },
      {
        searchQuery: 'pub_categories=bottoms',
        imagePath: '/static/categoryImages/wolven-bottoms.jpg',
        label: 'Bottoms',
      },
    ],
    styles: {
      ...defaultStyles,
      marketplaceColor: '#000000',
      marketplaceColorLight: '#777777',
      marketplaceColorDark: '#000000',
      matterColor: '#000000',
      // Wolven didn't want other colors from showing up on forms
      inputAttentionColor: '#000000',
      inputSuccessColor: '#000000',

      socialIconsColor: '#000000',
      socialIconsColorHover: '#000000',

      fontFamily: "AvenirNext, 'sofiapro', Helvetica",
      h1FontFamily: "AvenirNext, 'sofiapro', Helvetica",
      heroFontFamily: "AvenirNext, 'sofiapro', Helvetica",
      heroFontWeight: '600',

      topbarFontSizeMedium: '14px',

      backgroundImageUrl:
        "linear-gradient(-46deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3)), url('/static/heroImages/wolven.jpg')",
      backgroundImageUrlSmall:
        "linear-gradient(-46deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3)), url('/static/heroImages/wolven.jpg')",
      buttonColor: 'black',
      buttonColorHover: '#191919',
      buttonTextColor: 'white',
      buttonLetterSpacing: '2px',
      h1LetterSpacingSmall: '0px',
      h1LetterSpacingMedium: '0px',
      h1FontSizeMedium: '30px',
      h1FontSizeSmall: '24px',
      h2FontSize: '22px',
      h3FontSize: '18px',
      h4FontSizeMedium: '14px',
      pFontSizeSmall: '16px',
      pFontSizeMedium: '18px',
      buttonFontSize: '12px',
      footerImageUrl:
        "linear-gradient(-46deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3)), url('/static/footerImages/wolven.jpg')",
      footerImageUrlSmall:
        "linear-gradient(-46deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3)), url('/static/footerImages/wolven.jpg')",
      heroButtonColor: 'white',
      heroButtonHoverColor: '#e5e5e5',
      heroButtonTextHoverColor: 'black',
      heroButtonTextColor: 'black',
      // heroFontColor: 'black',
      landingPageBackgroundTint: '#EEEEEE',
    },
  },
  birdygrey: {
    ...defaultConfig,
    copy: {},
    // Birdy Grey's categories aren't helpful for finding an item
    landingPageLayoutOrder: [
      LANDING_PAGE_SECTION.HERO,
      LANDING_PAGE_SECTION.ABOUT,
      LANDING_PAGE_SECTION.FOOTER,
      LANDING_PAGE_SECTION.ABOUT_TREET,
    ],
    styles: {
      ...defaultStyles,
      fontFamily: "Karla, 'sofiapro', Helvetica",
      h1FontFamily: "Times New Roman, 'sofiapro', Helvetica",
      heroFontFamily: "Times New Roman, 'sofiapro', Helvetica",
    },
  },
  roughandtumble: {
    ...defaultConfig,
    shouldShowOtherItemsInSearch: false,
    landingPageLayoutOrder: [
      LANDING_PAGE_SECTION.HERO,
      LANDING_PAGE_SECTION.ABOUT,
      // TODO (anniew) waiting on r&t's response to categories
      // LANDING_PAGE_SECTION.CATEGORIES,
      LANDING_PAGE_SECTION.HOW_IT_WORKS,
      LANDING_PAGE_SECTION.ABOUT_TREET,
      LANDING_PAGE_SECTION.FOOTER,
    ],
    chooseStockImagesOption: CHOOSE_STOCK_IMAGES_OPTIONS.DEFAULT,
    allowUserGeneratedTitle: true,
    copy: {
      footerDescription:
        'Receive notifications about newly listed preloved designs, exciting Treet updates, and personalized recommendations “just for you”',
    },
    styles: {
      ...defaultStyles,
      organizationDescriptionTextAlign: 'center',
      heroFontSpacing: '5px',
      heroFontSizeDesktop: '36px',
      heroFontSizeLarge: '36px',
      fontFamily: 'futurabook',
      heroFontFamily: 'futurabook',
      heroSubTitleFontFamily: 'futurabook',
      heroContentAlignment: 'left',
      heroButtonMargin: '0 20px 0 0',
      heroSubTitleMaxWidth: '35%',
      backgroundImageUrl:
        "linear-gradient(-46deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3)), url('/static/heroImages/roughandtumble.jpg')",
      backgroundImageUrlSmall:
        "linear-gradient(-46deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3)), url('/static/heroImages/roughandtumble.jpg')",
      footerImageUrl:
        "linear-gradient(-46deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3)), url('/static/footerImages/roughandtumble.jpg')",
      footerImageUrlSmall:
        "linear-gradient(-46deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3)), url('/static/footerImages/roughandtumble.jpg')",
      buttonTextColor: '#ffffff',
      heroButtonColor: '#ceb386',
      h1FontWeightSmall: '700',
      h1FontWeightMedium: '700',
      h1FontFamily: 'futurabook',
      h1FontSizeMedium: '28px',
      heroButtonHoverColor: '#D9C4A2',
      heroButtonTextColor: '#ffffff',
      buttonColor: '#ceb386',
      buttonColorHover: '#D9C4A2',
      buttonLetterSpacing: '3px',
      landingPageBackgroundTint: '#F9F6F0',
      secondaryTextColor: '#ABABAB',
      marketplaceColor: '#ceb386',
      marketplaceColorLight: '#777777',
      marketplaceColorDark: '#000000',
    },
  },
  woodleylowe: {
    ...defaultConfig,
    // woodley + lowe will not have categories on treet
    copy: {},
    landingPageLayoutOrder: [
      LANDING_PAGE_SECTION.HERO,
      LANDING_PAGE_SECTION.ABOUT,
      LANDING_PAGE_SECTION.HOW_IT_WORKS,
      LANDING_PAGE_SECTION.ABOUT_TREET,
      LANDING_PAGE_SECTION.FOOTER,
    ],
    styles: {
      ...defaultStyles,
      marketplaceColor: '#d9cae7',
      marketplaceColorLight: '#fcf4e0',
      marketplaceColorDark: '#a2b4f0',
      landingPageBackgroundTint: '#f6f2ff',

      fontFamily: "AvenirNext, 'sofiapro', Helvetica",
      h1FontFamily: "AvenirNext, 'sofiapro', Helvetica",
      heroFontFamily: "AvenirNext, 'sofiapro', Helvetica",
      heroFontWeight: '600',

      topbarLinkFontSizeMedium: '12px',
      topbarLinkFontWeight: '700',
      topbarLinkTextTransform: 'uppercase',
      topbarLinkLetterSpacing: '0.08em',

      backgroundImageUrl:
        "linear-gradient(-46deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3)), url('/static/heroImages/woodleylowe.jpg')",
      backgroundImageUrlSmall:
        "linear-gradient(-46deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3)), url('/static/heroImages/woodleylowe.jpg')",
      footerImageUrl:
        "linear-gradient(-46deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3)), url('/static/footerImages/woodleylowe.jpg')",
      footerImageUrlSmall:
        "linear-gradient(-46deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3)), url('/static/footerImages/woodleylowe.jpg')",

      heroButtonColor: '#F9FB0E',
      heroButtonHoverColor: '#F9FB0E',
      heroButtonTextColor: 'black',
      heroButtonTextHoverColor: 'black',

      buttonColor: '#F9FB0E',
      buttonColorHover: '#F9FB0E',
      buttonTextColor: 'black',
      buttonFontSize: '15px',
      buttonFontWeight: '700',
      buttonLetterSpacing: '0.08em',
      buttonLineHeight: '20px',

      h1LetterSpacingSmall: '0px',
      h1LetterSpacingMedium: '0px',
      h1FontSizeSmall: '24px',
      h1FontSizeMedium: '30px',
      h1TextTransform: 'uppercase',
    },
  },
  relightcommunitymarketplace: {
    ...defaultConfig,
    copy: {},
    landingPageLayoutOrder: [
      LANDING_PAGE_SECTION.HERO,
      LANDING_PAGE_SECTION.ABOUT_OVERLAP,
      LANDING_PAGE_SECTION.HOW_IT_WORKS,
      LANDING_PAGE_SECTION.ABOUT_TREET,
      LANDING_PAGE_SECTION.FOOTER,
    ],
    styles: {
      ...defaultStyles,
      marketplaceColor: '#B6826A',
      marketplaceColorLight: '#F3F0EC',
      marketplaceColorDark: '#414141',
      landingPageBackgroundTint: '#DDCABC',

      fontFamily: "Avenir, 'sofiapro', Helvetica",
      h1FontFamily: "Avenir, 'sofiapro', Helvetica",
      heroFontFamily: "Avenir, 'sofiapro', Helvetica",
      heroFontWeight: '300',

      topbarLinkFontSizeMedium: '12px',
      topbarLinkFontWeight: '600',
      topbarLinkTextTransform: 'uppercase',
      topbarLinkLetterSpacing: '0.08em',
      topbarIconHeight: '20px',

      backgroundImageUrl:
        "linear-gradient(-46deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3)), url('/static/heroImages/relightcommunitymarketplace.jpg')",
      backgroundImageUrlSmall:
        "linear-gradient(-46deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3)), url('/static/heroImages/relightcommunitymarketplace.jpg')",
      footerImageUrl:
        "linear-gradient(-46deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3)), url('/static/footerImages/relightcommunitymarketplace.jpg')",
      footerImageUrlSmall:
        "linear-gradient(-46deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3)), url('/static/footerImages/relightcommunitymarketplace.jpg')",

      heroButtonColor: '#b6826a',
      heroButtonHoverColor: 'transparent',
      heroButtonTextColor: 'white',
      heroButtonTextHoverColor: 'white',
      heroButtonHoverBorder: '0.5px solid white',

      buttonColor: '#B6826A',
      buttonColorHover: '#B6826A',
      buttonTextColor: 'white',
      buttonFontWeight: '400',
      buttonLetterSpacing: '0.08em',
      buttonTextTransform: 'uppercase',

      h1LetterSpacingSmall: '0px',
      h1LetterSpacingMedium: '0px',
      h1FontSizeSmall: '28px',
      h1FontSizeMedium: '32px',

      h2TextTransform: 'uppercase',
      h2FontWeight: 400,
      h2FontSize: '20px',

      h3FontWeightSemiBold: 300,
      h3FontSize: '18px',

      h4FontSizeMedium: '16px',
      footerLogoHeight: '50px',
      footerLinkTextTransform: 'uppercase',
      footerLinkFontSize: '14px',
    },
  },
  seaestasurf: {
    ...defaultConfig,
    copy: {},
    homepageCategories: [
      {
        searchQuery: 'pub_category=Boardshorts',
        imagePath: '/static/categoryImages/seaestasurf-boardshort.jpg',
        label: 'Boardshorts',
      },
      {
        searchQuery: 'pub_category=Swimsuits',
        imagePath: '/static/categoryImages/seaestasurf-swimsuit.jpg',
        label: 'Swimsuits',
      },
    ],
    // ratio for search results page
    styles: {
      ...defaultStyles,
      marketplaceColor: '#9AB7B5',
      marketplaceColorLight: '#9AB7B5',
      marketplaceColorDark: '#7e7266',
      matterColor: '#5a5959',

      topbarLinkFontSizeMedium: '12px',
      topbarLinkFontWeight: '700',
      topbarLinkTextTransform: 'uppercase',
      topbarLinkLetterSpacing: '0.05em',

      fontFamily: "Futura, 'sofiapro', Helvetica",
      h1FontFamily: "Futura, 'sofiapro', Helvetica",
      heroFontFamily: "Futura, 'sofiapro', Helvetica",
      heroFontWeight: '500',
      heroFontTextTransform: 'uppercase',
      heroFontSizeDesktop: '46px',
      heroFontSizeLarge: '46px',
      heroFontSpacing: '2px',

      heroButtonColor: 'white',
      heroButtonHoverColor: 'white',
      heroButtonTextColor: 'black',
      heroButtonTextHoverColor: 'black',

      backgroundImageUrl:
        "linear-gradient(-46deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3)), url('/static/heroImages/seaestasurf.jpg')",
      backgroundImageUrlSmall:
        "linear-gradient(-46deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3)), url('/static/heroImages/seaestasurf.jpg')",
      footerImageUrl:
        "linear-gradient(-46deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3)), url('/static/footerImages/seaestasurf.jpg')",
      footerImageUrlSmall:
        "linear-gradient(-46deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3)), url('/static/footerImages/seaestasurf.jpg')",

      buttonColor: 'white',
      buttonTextColor: '#7e7266',
      buttonFontSize: '14px',
      buttonFontWeight: '700',
      buttonLetterSpacing: '0.08em',
      buttonTextTransform: 'uppercase',
      buttonBorder: '0.5px solid #7e7266',

      h1LetterSpacingSmall: '0px',
      h1LetterSpacingMedium: '0px',
      h1FontSizeMedium: '22px',
      h1FontSizeSmall: '22px',
      h1TextTransform: 'uppercase',
      footerLogoHeight: '48px',

      landingPageBackgroundTint: '#f8f6f2',
    },
  },
  vpl: {
    ...defaultConfig,
    landingPageLayoutOrder: [
      LANDING_PAGE_SECTION.HERO,
      LANDING_PAGE_SECTION.ABOUT,
      LANDING_PAGE_SECTION.HOW_IT_WORKS,
      LANDING_PAGE_SECTION.ABOUT_TREET,
      LANDING_PAGE_SECTION.FOOTER,
    ],
    copy: {},
    styles: {
      ...defaultStyles,
      backgroundImageUrl:
        "linear-gradient(-46deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3)), url('/static/heroImages/vpl.jpg')",
      backgroundImageUrlSmall:
        "linear-gradient(-46deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3)), url('/static/heroImages/vpl.jpg')",
      footerImageUrl:
        "linear-gradient(-46deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3)), url('/static/footerImages/vpl.jpg')",
      footerImageUrlSmall:
        "linear-gradient(-46deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3)), url('/static/footerImages/vpl.jpg')",

      landingPageBackgroundTint: '#d3d5c6',

      fontFamily: "IiIncrementsSans, 'sofiapro', Helvetica",
      h1FontFamily: "IiIncrementsSans, 'sofiapro', Helvetica",
      heroFontFamily: "IiIncrementsSans, 'sofiapro', Helvetica",
      buttonFontFamily: "IiIncrementsSans, 'sofiapro', Helvetica",

      topbarLinkFontSizeMedium: '14px',
      topbarLinkFontSizeSmall: '14px',

      h1FontSizeMedium: '36px',
      h1FontSizeSmall: '28px',
      h1FontWeightMedium: '600',
      h1FontWeightSmall: '600',
      h1LetterSpacingMedium: 0,
      h1LetterSpacingSmall: 0,

      h2FontSize: '27px',
      h2FontWeight: '400',
      h2TextTransform: 'uppercase',

      h3FontSize: '16px',

      heroFontTextTransform: 'uppercase',
      heroButtonColor: '#d3d5c6',
      heroButtonHoverColor: 'white',
      heroButtonTextColor: '#000000',
      heroButtonTextHoverColor: '#000000',
      heroFontWeight: '600',

      pFontSizeMedium: '14px',
      pFontSizeSmall: '14px',

      marketplaceColor: '#34362b',
      matterColor: '#34362b',
      matterColorDark: '#34362b',

      buttonColor: '#d3d5c6',
      buttonColorHover: 'white',
      buttonTextColor: '#000000',
      buttonTextTransform: 'uppercase',
      buttonFontSize: '16px',
    },
  },
  ranchroad: {
    ...defaultConfig,
    copy: {},
    landingPageLayoutOrder: [
      LANDING_PAGE_SECTION.HERO,
      LANDING_PAGE_SECTION.ABOUT,
      LANDING_PAGE_SECTION.HOW_IT_WORKS,
      LANDING_PAGE_SECTION.ABOUT_TREET,
      LANDING_PAGE_SECTION.FOOTER,
    ],
    styles: {
      ...defaultStyles,
      fontFamily: "AvenirNext, 'sofiapro', Helvetica",
      h1FontFamily: "Americana, 'sofiapro', Helvetica",
      heroFontFamily: "Americana, 'sofiapro', Helvetica",
    },
  },
  curatorsf: {
    ...defaultConfig,
    copy: {},
    landingPageLayoutOrder: [
      LANDING_PAGE_SECTION.HERO,
      LANDING_PAGE_SECTION.ABOUT,
      LANDING_PAGE_SECTION.HOW_IT_WORKS,
      LANDING_PAGE_SECTION.ABOUT_TREET,
      LANDING_PAGE_SECTION.FOOTER,
    ],

    // ratio for search results page
    styles: {
      ...defaultStyles,
      marketplaceColor: '#8D4B49',
      marketplaceColorDark: '#EFEFEF',

      socialIconsColor: '#000000',
      socialIconsColorHover: '#000000',

      fontFamily: "Futura, 'sofiapro', Helvetica",
      h1FontFamily: "Futura, 'sofiapro', Helvetica",
      heroFontFamily: "Futura, 'sofiapro', Helvetica",
      heroFontWeight: '700',

      topbarLinkFontSizeMedium: '12px',
      topbarLinkFontWeight: '700',
      topbarLinkTextTransform: 'uppercase',
      topbarLinkLetterSpacing: '0.1em',

      backgroundImageUrl:
        "linear-gradient(-46deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3)), url('/static/heroImages/curatorsf.jpg')",
      backgroundImageUrlSmall:
        "linear-gradient(-46deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3)), url('/static/heroImages/curatorsf.jpg')",
      buttonColor: '#8D4B49',
      buttonColorHover: '#8D4B49',
      buttonTextColor: 'white',
      buttonFontSize: '15px',
      buttonFontWeight: '700',
      buttonLetterSpacing: '0.1em',
      buttonLineHeight: '20px',
      buttonTextTransform: 'uppercase',

      h1FontSizeMedium: '30px',
      h1FontSizeSmall: '24px',
      h1LetterSpacingSmall: '0px',
      h1LetterSpacingMedium: '0px',

      h2FontSize: '22px',
      footerImageUrl:
        "linear-gradient(-46deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3)), url('/static/footerImages/curatorsf.jpg')",
      footerImageUrlSmall:
        "linear-gradient(-46deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3)), url('/static/footerImages/curatorsf.jpg')",
      heroButtonColor: '#8D4B49',
      heroButtonHoverColor: '#8D4B49',
      heroButtonTextColor: 'white',
      heroButtonTextHoverColor: 'white',
      landingPageBackgroundTint: '#EFEFEF',
    },
  },
  jujube: {
    ...defaultConfig,
    copy: {},
    styles: {
      ...defaultStyles,

      marketplaceColor: '#E9B3B6',
      marketplaceColorDark: '#737577',

      fontFamily: "SourceSansPro, 'sofiapro', Helvetica",
      h1FontFamily: "SourceSansPro, 'sofiapro', Helvetica",
      heroFontFamily: "SourceSansPro, 'sofiapro', Helvetica",
    },
  },
  helm: {
    ...defaultConfig,
    styles: {
      ...defaultStyles,

      marketplaceColor: '#37464f',
      matterColor: '#000000',

      socialIconsColor: '#000000',

      fontFamily: "LibreBaskerville, 'sofiapro', Helvetica",
      h1FontFamily: "LibreBaskerville, 'sofiapro', Helvetica",
      heroFontFamily: "LibreBaskerville, 'sofiapro', Helvetica",
    },
    // These fields are overriden in V2
    copy: {},
  },
  bryr: {
    ...defaultConfig,
    styles: {
      ...defaultStyles,

      marketplaceColor: '#BBB2B2',
      matterColor: '#4a4a4a',

      socialIconsColor: '#000000',

      fontFamily: "Helvetica, 'sofiapro'",
      h1FontFamily: "Helvetica, 'sofiapro'",
      heroFontFamily: "Helvetica, 'sofiapro'",
    },
    // These fields are overriden in V2
    copy: {},
  },
  miakoda: {
    ...defaultConfig,
    styles: {
      ...defaultStyles,

      marketplaceColor: '#D0B0A6',
      matterColor: '#000000',

      fontFamily: "Quicksand, 'sofiapro', Helvetica",
      h1FontFamily: "Quicksand, 'sofiapro', Helvetica",
      heroFontFamily: "Quicksand, 'sofiapro', Helvetica",
    },
    // These fields are overriden in V2
    copy: {},
  },
  lykkewullf: {
    ...defaultConfig,
    styles: {
      ...defaultStyles,

      marketplaceColor: '#000000',
      matterColor: '#222222',
      matterColorDark: '#000000',

      socialIconsColor: '#222222',
      socialIconsColorHover: '#000000',

      fontFamily: "OpenSans, 'sofiapro', Helvetica",
      h1FontFamily: "Lora, 'sofiapro', Helvetica",
      heroFontFamily: "Lora, 'sofiapro', Helvetica",
    },
    // These fields are overriden in V2
    copy: {},
  },
  statethelabel: {
    ...defaultConfig,
    styles: {
      ...defaultStyles,
      fontFamily: "Andale Mono, 'sofiapro', Helvetica",
      h1FontFamily: "Andale Mono, 'sofiapro', Helvetica",
      heroFontFamily: "Old Standard, 'sofiapro', Helvetica",
    },
    // These fields are overriden in V2
    copy: {},
  },
  statebags: {
    ...defaultConfig,
    styles: {
      ...defaultStyles,
      fontFamily: "GT-America, 'sofiapro', Helvetica",
      h1FontFamily: "GT-America, 'sofiapro', Helvetica",
      heroFontFamily: "GT-America, 'sofiapro', Helvetica",
    },
    // These fields are overriden in V2
    copy: {},
  },
  betterlifebags: {
    ...defaultConfig,
    styles: {
      ...defaultStyles,
      borderRadius: '4px',
      borderRadiusHalf: '2px',

      fontFamily: "'sofiapro', Helvetica",
      h1FontFamily: "'sofiapro', Helvetica",
      heroFontFamily: "'sofiapro', Helvetica",
    },
    // These fields are overriden in V2
    copy: {},
  },
  myles: {
    ...defaultConfig,
    styles: defaultStyles,
    // These fields are overriden in V2
    copy: {},
  },
  youer: {
    ...defaultConfig,
    styles: {
      ...defaultStyles,

      marketplaceColor: '#6d2f7c',
      matterColor: '#6d2f7c',
      matterColorDark: '#5d286a',

      socialIconsColor: '#6d2f7c',
      socialIconsColorHover: '#5d286a',

      fontFamily: "DIN, 'sofiapro', Helvetica",
      h1FontFamily: "Axis, 'sofiapro', Helvetica",
      heroFontFamily: "Axis, 'sofiapro', Helvetica",
    },
    // These fields are overriden in V2
    copy: {},
  },
  wornhub: {
    ...defaultConfig,
    styles: {
      ...defaultStyles,
      fontFamily: "Calibre, 'sofiapro', Helvetica",
      h1FontFamily: "Calibre, 'sofiapro', Helvetica",
      heroFontFamily: "Calibre, 'sofiapro', Helvetica",
    },
    // These fields are overriden in V2
    copy: {},
  },
  saylor: {
    ...defaultConfig,
    styles: {
      ...defaultStyles,
      fontFamily: "Butler, 'Helvetica'",
      h1FontFamily: "Butler, 'Helvetica'",
      heroFontFamily: "Marianina, 'Helvetica'",
    },
    // These fields are overriden in V2
    copy: {},
  },
  whimsyandrow: {
    ...defaultConfig,
    styles: {
      ...defaultStyles,
      fontFamily: "Brother1816, 'Helvetica'",
      h1FontFamily: "Brother1816, 'Helvetica'",
      heroFontFamily: "Brother1816, 'Helvetica'",
    },
    // These fields are overriden in V2
    copy: {},
  },
  bohemianmama: {
    ...defaultConfig,
    styles: {
      ...defaultStyles,
      fontFamily: "Moderat, 'Helvetica'",
      h1FontFamily: "Moderat, 'Helvetica'",
      heroFontFamily: "DreamAvenue, 'Helvetica'",
    },
    // These fields are overriden in V2
    copy: {},
  },
  ryleeandcru: {
    ...defaultConfig,
    styles: defaultStyles,
    // These fields are overriden in V2
    copy: {},
  },
  quincymae: {
    ...defaultConfig,
    styles: defaultStyles,
    // These fields are overriden in V2
    copy: {},
  },
  vetta: {
    ...defaultConfig,
    styles: {
      ...defaultStyles,
      fontFamily: "AttenNew, 'Montserrat'",
      h1FontFamily: "ParkLane, 'Montserrat'",
      heroFontFamily: "ParkLane, 'Montserrat'",
    },
    // These fields are overriden in V2
    copy: {},
  },
  reloved: {
    ...defaultConfig,
    styles: {
      ...defaultStyles,

      borderRadius: '32px',
      borderRadiusHalf: '32px',

      fontFamily: "Nunito Sans, 'Helvetica'",
      h1FontFamily: "Century Gothic, 'Helvetica'",
      heroFontFamily: "Century Gothic, 'Helvetica'",
    },
    // These fields are overriden in V2
    copy: {},
  },
  wildrye: {
    ...defaultConfig,
    styles: defaultStyles,
    // These fields are overriden in V2
    copy: {},
  },
  roolee: {
    ...defaultConfig,
    styles: defaultStyles,
    // These fields are overriden in V2
    copy: {},
  },
  viscata: {
    ...defaultConfig,
    styles: {
      ...defaultStyles,
      fontFamily: 'Grifo, Helvetica',
      h1FontFamily: 'Grifo, Helvetica',
      heroFontFamily: 'Grifo, Helvetica',
    },
    // These fields are overriden in V2
    copy: {},
  },
  skhoop: {
    ...defaultConfig,
    styles: {
      ...defaultStyles,
      fontFamily: 'Arial, Georgia Pro',
      h1FontFamily: 'Arial, Georgia Pro',
      heroFontFamily: 'Arial, Georgia Pro',
    },
    // These fields are overriden in V2
    copy: {},
  },
  kyrgies: {
    ...defaultConfig,
    styles: {
      ...defaultStyles,
      fontFamily: 'Lato, Arial',
      h1FontFamily: 'Lato, Arial',
      heroFontFamily: 'Lato, Arial',
    },
    // These fields are overriden in V2
    copy: {},
  },
  jamesstreetco: {
    ...defaultConfig,
    styles: {
      ...defaultStyles,
      fontFamily: 'Helvetica, Arial',
      h1FontFamily: 'Helvetica, Arial',
      heroFontFamily: 'Helvetica, Arial',
    },
    // These fields are overriden in V2
    copy: {},
  },
  lunapaca: {
    ...defaultConfig,
    styles: {
      ...defaultStyles,

      borderRadius: '32px',
      borderRadiusHalf: '32px',

      fontFamily: 'Assistant, Helvetica, Arial',
      h1FontFamily: 'Dosis, Helvetica, Arial',
      heroFontFamily: 'Dosis, Helvetica, Arial',
    },
    // These fields are overriden in V2
    copy: {},
  },
  elizabethsuzann: {
    ...defaultConfig,
    styles: {
      ...defaultStyles,
      fontFamily: 'Brandon, Helvetica, Arial',
      h1FontFamily: 'Freight, Helvetica, Arial',
      heroFontFamily: 'Freight, Helvetica, Arial',
    },
    copy: {},
  },
  doen: {
    ...defaultConfig,
    shouldUseStaticSizes: false,
    landingPageLayoutOrder: [
      LANDING_PAGE_SECTION.HERO,
      LANDING_PAGE_SECTION.HOW_IT_WORKS,
      LANDING_PAGE_SECTION.ABOUT_TREET,
    ],
    styleType: StyleType.Condensed,
    fontConfig: {
      [FontConfigSection.AboutPageGeneralInfoTitle]: 'h2',
      [FontConfigSection.AboutPageFAQTitle]: 'subtitle1',
      [FontConfigSection.FieldLabel]: 'subtitle1',
    },
    styles: {
      ...defaultStyles,
      footerLogoHeight: '60px',
      fontFamily: 'FoundersCaslon, Helvetica, Arial',
      h1FontFamily: 'ShellRoundhand, Helvetica, Arial',
      heroFontFamily: 'FoundersCaslon, Helvetica, Arial',
    },
    // These fields are overriden in V2
    copy: {},
  },
  alysoneastman: {
    ...defaultConfig,
    styles: {
      ...defaultStyles,
      fontFamily: 'Alize, Helvetica, Arial',
      h1FontFamily: 'Alize, Helvetica, Arial',
      heroFontFamily: 'Alize, Helvetica, Arial',
    },
    copy: {},
  },
  tentree: {
    ...defaultConfig,
    styles: {
      ...defaultStyles,
      fontFamily: 'MerloNeue, Helvetica, Arial',
      h1FontFamily: 'MerloNeue, Helvetica, Arial',
      heroFontFamily: 'MerloNeue, Helvetica, Arial',
    },
    copy: {},
  },
  'tentree-ca': {
    ...defaultConfig,
    styles: {
      ...defaultStyles,
      fontFamily: 'MerloNeue, Helvetica, Arial',
      h1FontFamily: 'MerloNeue, Helvetica, Arial',
      heroFontFamily: 'MerloNeue, Helvetica, Arial',
    },
    copy: {},
  },
  weisswatchcompany: {
    ...defaultConfig,
    styles: {
      ...defaultStyles,
      fontFamily: 'ProximaNova, Helvetica, Arial',
      h1FontFamily: 'Gotham, Helvetica, Arial',
      heroFontFamily: 'Gotham, Helvetica, Arial',
    },
    copy: {},
  },
  surlacote: {
    ...defaultConfig,
    styles: {
      ...defaultStyles,
      fontFamily: 'Amiri, Times New Roman',
      h1FontFamily: 'Amiri, Times New Roman',
      heroFontFamily: 'Amiri, Times New Roman',
    },
    copy: {},
  },
  allwear: {
    ...defaultConfig,
    styles: {
      ...defaultStyles,
      fontFamily: 'Futura, Times New Roman',
      h1FontFamily: 'Futura, Times New Roman',
      heroFontFamily: 'Futura, Times New Roman',
    },
    copy: {},
  },
  moderncitizen: {
    ...defaultConfig,
    styles: {
      ...defaultStyles,
      fontFamily: 'SweetSans, Arial',
      h1FontFamily: 'SweetSans, Arial',
      heroFontFamily: 'SweetSans, Arial',
    },
    copy: {},
  },
  esemblybaby: {
    ...defaultConfig,
    styles: {
      ...defaultStyles,
      borderRadius: '10px',
      borderRadiusHalf: '10px',
      fontFamily: 'Gotham Book, Arial',
      h1FontFamily: 'Archer, Arial',
      heroFontFamily: 'Archer, Arial',
    },
    copy: {},
  },
  wayre: {
    ...defaultConfig,
    styles: {
      ...defaultStyles,
      borderRadius: '20px',
      borderRadiusHalf: '10px',
      fontFamily: "'sofiapro', Helvetica, Arial, sans-serif",
      h1FontFamily: "'sofiapro', Helvetica, Arial, sans-serif",
      heroFontFamily: "'sofiapro', Helvetica, Arial, sans-serif",
    },
    copy: {},
  },
  thebeeandthefox: {
    ...defaultConfig,
    styles: {
      ...defaultStyles,
      fontFamily: 'DINNextSlab, Helvetica, Arial',
      h1FontFamily: 'MariposaSans, Helvetica, Arial',
      heroFontFamily: 'MariposaSans, Helvetica, Arial',
    },
    copy: {},
  },
  tuesdayofcalifornia: {
    ...defaultConfig,
    styles: {
      ...defaultStyles,
      borderRadius: '4px',
      borderRadiusHalf: '4px',
      fontFamily: 'SportingGrotesque, Helvetica, Arial, sans-serif',
      h1FontFamily: 'Manofa, Helvetica, Arial, sans-serif',
      heroFontFamily: 'Manofa, Helvetica, Arial, sans-serif',
      footerLogoHeight: '48px',
    },
    copy: {},
  },
  kirrinfinch: {
    ...defaultConfig,
    styles: {
      ...defaultStyles,
      borderRadius: '3px',
      borderRadiusHalf: '3px',
      fontFamily: 'Brandon Grotesque, Helvetica, Arial, sans-serif',
      h1FontFamily: 'Granville, Helvetica, Arial, sans-serif',
      heroFontFamily: 'Granville, Helvetica, Arial, sans-serif',
    },
    copy: {},
  },
  thebeaufortbonnetcompany: {
    ...defaultConfig,
    styles: {
      ...defaultStyles,
      fontFamily: 'Gotham, Helvetica, Arial, sans-serif',
      h1FontFamily: 'Times New Roman, Helvetica, Arial, sans-serif',
      heroFontFamily: 'Times New Roman, Helvetica, Arial, sans-serif',
      footerLogoHeight: '48px',
    },
    copy: {},
  },
  mikimiette: {
    ...defaultConfig,
    styles: {
      ...defaultStyles,
      fontFamily: "Quicksand, 'sofiapro', Helvetica",
      h1FontFamily: "Quicksand, 'sofiapro', Helvetica",
      heroFontFamily: "Quicksand, 'sofiapro', Helvetica",
    },
    copy: {},
  },
  ziabird: {
    ...defaultConfig,
    styles: {
      ...defaultStyles,
      fontFamily: "Inconsolata, 'sofiapro', Helvetica",
      h1FontFamily: "Inconsolata, 'sofiapro', Helvetica",
      heroFontFamily: "Inconsolata, 'sofiapro', Helvetica",
    },
    copy: {},
  },
  treeton: {
    ...defaultConfig,
    styles: {
      ...defaultStyles,
      fontFamily: 'Poppins Arial, sans-serif',
      h1FontFamily: 'Poppins, Arial, sans-serif',
      heroFontFamily: 'Poppins, Arial, sans-serif',
    },
    copy: {},
  },
  joahlove: {
    ...defaultConfig,
    styles: {
      ...defaultStyles,
      fontFamily: 'Karla, Arial, sans-serif',
      h1FontFamily: 'Karla, Arial, sans-serif',
      heroFontFamily: 'Karla, Arial, sans-serif',
    },
    copy: {},
  },
  opheliaandindigo: {
    ...defaultConfig,
    styles: {
      ...defaultStyles,
      fontFamily: 'AvenirNext, sans-serif',
      h1FontFamily: 'AvenirNext, Arial, sans-serif',
      heroFontFamily: 'AvenirNext, Arial, sans-serif',
    },
    copy: {},
  },
  treetfonts: {
    ...defaultConfig,
    styles: {
      ...defaultStyles,
      fontFamily: 'Poppins Arial, sans-serif',
      h1FontFamily: 'Poppins, Arial, sans-serif',
      heroFontFamily: 'Poppins, Arial, sans-serif',
    },
    copy: {},
  },
  whyandwhale: {
    ...defaultConfig,
    styles: {
      ...defaultStyles,
      fontFamily: 'Futura, Arial, sans-serif',
      h1FontFamily: 'ITCAvantGarde, Arial, sans-serif',
      heroFontFamily: 'ITCAvantGarde, Arial, sans-serif',
    },
    copy: {},
  },
  nogu: {
    ...defaultConfig,

    styles: {
      ...defaultStyles,
      fontFamily: 'Montserrat, sans-serif',
      h1FontFamily: 'Montserrat, sans-serif',
      heroFontFamily: 'Montserrat, sans-serif',
      footerLogoHeight: '48px',
    },
    copy: {},
  },
  southerntide: {
    ...defaultConfig,

    styles: {
      ...defaultStyles,
      fontFamily: 'Gotham Narrow Book, sans-serif',
      h1FontFamily: 'Gotham Extra Narrow Bold, sans-serif',
      heroFontFamily: 'Gotham Extra Narrow Bold, sans-serif',
    },
    copy: {},
  },
  southerntaters: {
    ...defaultConfig,
    styles: {
      ...defaultStyles,
      fontFamily: 'Work Sans, sans-serif',
      h1FontFamily: 'Oswald, sans-serif',
      heroFontFamily: 'Oswald, sans-serif',
    },
    copy: {},
  },
  iksplor: {
    ...defaultConfig,
    styles: {
      ...defaultStyles,
      fontFamily: 'seravek-web, sans-serif',
      h1FontFamily: 'seravek-web, sans-serif',
      heroFontFamily: 'seravek-web, sans-serif',
    },
    copy: {},
  },
  goodlife: {
    ...defaultConfig,
    styles: {
      ...defaultStyles,
      fontFamily: 'Lato, sans-serif',
      h1FontFamily: 'Lato, sans-serif',
      heroFontFamily: 'Lato, sans-serif',
    },
    copy: {},
  },
  maboclothier: {
    ...defaultConfig,
    styles: {
      ...defaultStyles,
      fontFamily: 'Nobel, sans-serif',
      h1FontFamily: 'Nobel, sans-serif',
      heroFontFamily: 'Nobel, sans-serif',
    },
    copy: {},
  },
  westernandco: {
    ...defaultConfig,
    styles: {
      ...defaultStyles,
      fontFamily: 'OpenSans, sans-serif',
      h1FontFamily: 'OpenSans, sans-serif',
      heroFontFamily: 'Roboto Condensed, sans-serif',
    },
    copy: {},
  },
  sarahwellsbags: {
    ...defaultConfig,
    styles: {
      ...defaultStyles,
      fontFamily: "'sofiapro'",
      h1FontFamily: "'sofiapro'",
      heroFontFamily: "SunValley, 'sofiapro'",
    },
    copy: {},
  },
  dollskill: {
    ...defaultConfig,
    styles: {
      ...defaultStyles,
      fontFamily: 'Sgro',
      h1FontFamily: 'Sgro',
      heroFontFamily: 'Sgro',
    },
    copy: {},
  },
  kitsbow: {
    ...defaultConfig,
    styles: {
      ...defaultStyles,
      fontFamily: 'Helvetica',
      h1FontFamily: 'AvenirNext',
      heroFontFamily: 'AvenirNext',
    },
    copy: {},
  },
  teekiboutique: {
    ...defaultConfig,
    styles: {
      ...defaultStyles,
      fontFamily: 'DIN Neuzeit Grotesk, Arial, Tahoma, Verdana, sans-serif',
      h1FontFamily: 'Josefin Sans, sans-serif',
      heroFontFamily: 'Josefin Sans, sans-serif',
    },
    copy: {},
  },
  rachelantonoff: {
    ...defaultConfig,
    styles: {
      ...defaultStyles,
      fontFamily: 'Futura, sans-serif',
      h1FontFamily: 'Futura, sans-serif',
      heroFontFamily: 'Futura, sans-serif',
    },
    copy: {},
  },
  burjushoes: {
    ...defaultConfig,
    // TODO (JL|TREET-3957): Move custom variant options to Contentful
    customVariantDropdownOptions: [
      {
        name: 'Sole',
        values: [
          {
            key: 'Street',
            label: 'Street',
          },
          {
            key: 'Rubber',
            label: 'Rubber',
          },
          {
            key: 'Suede',
            label: 'Suede',
          },
        ],
      },
      {
        name: 'Heel Height and Style',
        values: [
          {
            key: '2.5 inch Slim',
            label: '2.5 inch Slim',
          },
          {
            key: '3 inch Flared',
            label: '3 inch Flared',
          },
          {
            key: '3 inch Slim',
            label: '3 inch Slim',
          },
          {
            key: '3.5 inch Stiletto',
            label: '3.5 inch Stiletto',
          },
          {
            key: '4 inch Stiletto',
            label: '4 inch Stiletto',
          },
          {
            key: '4 inch Chunky',
            label: '4 inch Chunky',
          },
          {
            key: '4.2 Slim Stiletto',
            label: '4.2 Slim Stiletto',
          },
          {
            key: '4.2 Chunky Flared',
            label: '4.2 Chunky Flared',
          },
          {
            key: '6 inch Platform',
            label: '6 inch Platform',
          },
          {
            key: '7 inch Platform',
            label: '7 inch Platform',
          },
          {
            key: '8 inch Platform',
            label: '8 inch Platform',
          },
        ],
      },
    ],
    styles: {
      ...defaultStyles,
    },
    copy: {},
  },
  ettitude: {
    ...defaultConfig,
    chooseStockImagesOption: CHOOSE_STOCK_IMAGES_OPTIONS.ETTITUDE,
    styles: {
      ...defaultStyles,

      fontFamily: 'Circular, Helvetica, sans-serif',
      h1FontFamily: 'Circular, Helvetica, sans-serif',
      heroFontFamily: 'Circular, Helvetica, sans-serif',
    },
    copy: {},
  },
  princesspolly: {
    ...defaultConfig,
    styles: {
      ...defaultStyles,
      fontFamily: 'Roboto Mono, sans-serif',
      h1FontFamily: 'Roboto Mono, sans-serif',
      heroFontFamily: 'Roboto Mono, sans-serif',
    },
    copy: {},
  },
  parkerclay: {
    ...defaultConfig,
    styles: {
      ...defaultStyles,
      fontFamily: 'Avenir, Helvetica',
      h1FontFamily: 'Avenir, Helvetica',
      heroFontFamily: 'Avenir, Helvetica',
    },
    copy: {},
  },
  quinceandco: {
    ...defaultConfig,
    styles: {
      ...defaultStyles,
      fontFamily: 'Futura, Helvetica',
      h1FontFamily: 'Sentinel, serif',
      heroFontFamily: 'Futura, Helvetica',
    },
    copy: {},
  },
  crownforeverla: {
    ...defaultConfig,
    styles: {
      ...defaultStyles,
      fontFamily: 'Karla, sans-serif',
      h1FontFamily: 'Karla, sans-serif',
      heroFontFamily: 'Karla, sans-serif',
    },
    copy: {},
  },
  mpatmos: {
    ...defaultConfig,
    styles: {
      ...defaultStyles,
      fontFamily: 'Hoefler Text, Times New Roman',
      h1FontFamily: 'Knockout, Impact, sans-serif',
      heroFontFamily: 'Knockout, Impact, sans-serif',
    },
    copy: {},
  },
  romanandriley: {
    ...defaultConfig,
    styles: {
      ...defaultStyles,
      fontFamily: 'LibreBaskerville, Times New Roman',
      h1FontFamily: 'Circular, Helvetica, sans-serif',
      heroFontFamily: 'Circular, Helvetica, sans-serif',
    },
    copy: {},
  },
  aybl: {
    ...defaultConfig,
    styles: {
      ...defaultStyles,
      fontFamily: "Futura, 'sofiapro', Helvetica",
      h1FontFamily: 'Montserrat, sans-serif',
      heroFontFamily: 'Montserrat, sans-serif',
    },
    copy: {},
  },
  lafemmeapero: {
    ...defaultConfig,
    styles: {
      ...defaultStyles,
      fontFamily: 'Prata, Times New Roman, serif',
      h1FontFamily: 'Prata, Times New Roman, serif',
      heroFontFamily: 'Prata, Times New Roman, serif',
    },
    copy: {},
  },
  tenable6: {
    ...defaultConfig,
    styles: {
      ...defaultStyles,
      fontFamily: 'AvenirNext, Times New Roman, serif',
      h1FontFamily: 'AvenirNext, Times New Roman, serif',
      heroFontFamily: 'AvenirNext, Times New Roman, serif',
    },
    copy: {},
  },
  cotopaxi: {
    ...defaultConfig,
    styles: {
      ...defaultStyles,
    },
    copy: {},
  },
  simons: {
    ...defaultConfig,
    styles: {
      ...defaultStyles,
    },
    copy: {},
  },
  anian: {
    ...defaultConfig,
    chooseStockImagesOption: CHOOSE_STOCK_IMAGES_OPTIONS.ANIAN,
    styles: {
      ...defaultStyles,
      // Move to contentful if more brands want skinny "add to bag" buttons
      ListingPage_buttonPadding: '3px',
    },
    copy: {},
  },
  goldieandace: {
    ...defaultConfig,
    styles: {
      ...defaultStyles,
      fontFamily: 'Helvetica, sans-serif',
      h1FontFamily: 'Helvetica, sans-serif',
      heroFontFamily: 'Helvetica, sans-serif',
      // Move to contentful if more brands want skinny "add to bag" buttons
      ListingPage_buttonPadding: '3px',
    },
    copy: {},
  },
  kickeepants: {
    ...defaultConfig,
    styles: {
      ...defaultStyles,
      fontFamily: 'OpenSans, Times New Roman, serif',
      h1FontFamily: 'OpenSans, Times New Roman, serif',
      heroFontFamily: 'OpenSans, Arial, sans-serif',
    },
    copy: {},
  },
  mpgsportcanada: {
    ...defaultConfig,
    styles: {
      ...defaultStyles,
      fontFamily: 'Montserrat, sans-serif',
      h1FontFamily: 'Montserrat, sans-serif',
      heroFontFamily: 'Montserrat, sans-serif',
    },
    copy: {},
  },
  mpgsportusa: {
    ...defaultConfig,
    styles: {
      ...defaultStyles,
      fontFamily: 'Montserrat, sans-serif',
      h1FontFamily: 'Montserrat, sans-serif',
      heroFontFamily: 'Montserrat, sans-serif',
    },
    copy: {},
  },
  briarbaby: {
    ...defaultConfig,
    styles: {
      ...defaultStyles,
      fontFamily: 'Lato, Helvetica, sans-serif',
      h1FontFamily: 'Lato, Helvetica sans-serif',
      heroFontFamily: 'PlayfairDisplay, Lato, Helvetica sans-serif',
    },
    copy: {},
  },
  'encircled-ca': {
    ...defaultConfig,
    styles: defaultStyles,
    copy: {},
  },
  'encircled-us': {
    ...defaultConfig,
    styles: defaultStyles,
    copy: {},
  },
  hernestproject: {
    ...defaultConfig,
    styles: defaultStyles,
    copy: {},
  },
  velvety: {
    ...defaultConfig,
    styles: defaultStyles,
    copy: {},
  },
  mignonnegavigan: {
    ...defaultConfig,
    styles: defaultStyles,
    copy: {},
  },
  vitaeapparel: {
    ...defaultConfig,
    styles: defaultStyles,
    copy: {},
  },
  talulah: {
    ...defaultConfig,
    styles: defaultStyles,
    copy: {},
  },
  gerganaivanova: {
    ...defaultConfig,
    styles: defaultStyles,
    copy: {},
  },
  goldietees: {
    ...defaultConfig,
    styles: defaultStyles,
    copy: {},
  },
  jillianboustred: {
    ...defaultConfig,
    styles: defaultStyles,
    copy: {},
  },
  wray: {
    ...defaultConfig,
    styles: defaultStyles,
    copy: {},
  },
  neunomads: {
    ...defaultConfig,
    styles: defaultStyles,
    copy: {},
  },
  prismboutique: {
    ...defaultConfig,
    styles: defaultStyles,
    copy: {},
  },
  laudethelabel: {
    ...defaultConfig,
    styles: defaultStyles,
    copy: {},
  },
  kpopexchange: {
    ...defaultConfig,
    styles: defaultStyles,
    copy: {},
  },
  dl1961: {
    ...defaultConfig,
    styles: {
      ...defaultStyles,
      footerLogoHeight: '16px',
    },
    copy: {},
  },
  baabuk: {
    ...defaultConfig,
    styles: defaultStyles,
    copy: {},
  },
  elevatedfaith: {
    ...defaultConfig,
    styles: defaultStyles,
    copy: {},
  },
  asrv: {
    ...defaultConfig,
    styles: defaultStyles,
    copy: {},
  },
  lovedbaby: {
    ...defaultConfig,
    styles: defaultStyles,
    copy: {},
  },
  callahan: {
    ...defaultConfig,
    styles: defaultStyles,
    copy: {},
  },
  littleandlively: {
    ...defaultConfig,
    styles: defaultStyles,
    copy: {},
  },
  osoandme: {
    ...defaultConfig,
    styles: { ...defaultStyles, borderRadius: '4px', borderRadiusHalf: '2px' },
    copy: {},
  },
  pakt: {
    ...defaultConfig,
    styles: defaultStyles,
    copy: {},
  },
  elfinlosangeles: {
    ...defaultConfig,
    styles: defaultStyles,
    copy: {},
  },
  crosbybymollieburch: {
    ...defaultConfig,
    styles: defaultStyles,
    copy: {},
  },
  ironandresin: {
    ...defaultConfig,
    styles: { ...defaultStyles, footerLogoHeight: '60px' },
    copy: {},
  },
  nuiorganics: {
    ...defaultConfig,
    styles: defaultStyles,
    copy: {},
  },
  costumebox: {
    ...defaultConfig,
    styles: defaultStyles,
    copy: {},
  },
  livsn: {
    ...defaultConfig,
    styles: defaultStyles,
    copy: {},
  },
  forloveandlemons: {
    ...defaultConfig,
    styles: defaultStyles,
    copy: {},
  },
  namai: {
    ...defaultConfig,
    styles: defaultStyles,
    copy: {},
  },
  girlfriend: {
    ...defaultConfig,
    styles: defaultStyles,
    copy: {},
  },
  superx: {
    ...defaultConfig,
    styles: defaultStyles,
    copy: {},
  },
  sforce: {
    ...defaultConfig,
    styles: defaultStyles,
    copy: {},
  },
  ministryofsupply: {
    ...defaultConfig,
    styles: defaultStyles,
    copy: {},
  },
  standardandstrange: {
    ...defaultConfig,
    styles: defaultStyles,
    copy: {},
  },
  raveival: {
    ...defaultConfig,
    styles: defaultStyles,
    copy: {},
  },
  electricyoga: {
    ...defaultConfig,
    styles: defaultStyles,
    copy: {},
  },
  creocards: {
    ...defaultConfig,
    styles: defaultStyles,
    copy: {},
  },
  huxbaby: {
    ...defaultConfig,
    styles: { ...defaultStyles, marketplacebrandDirectIconSize: '90px' },
    copy: {},
  },
  minimioche: {
    ...defaultConfig,
    styles: defaultStyles,
    copy: {},
  },
  allegrajames: {
    ...defaultConfig,
    styles: defaultStyles,
    copy: {},
  },
  musethreads: {
    ...defaultConfig,
    styles: defaultStyles,
    copy: {},
  },
  grayers: {
    ...defaultConfig,
    styles: defaultStyles,
    copy: {},
  },
  tallsize: {
    ...defaultConfig,
    styles: defaultStyles,
    copy: {},
    customVariantDropdownOptions: [
      {
        name: 'inseam',
        values: [
          { key: '32"', label: '32"' },
          { key: '33"', label: '33"' },
          { key: '34"', label: '34"' },
          { key: '35"', label: '35"' },
          { key: '36"', label: '36"' },
          { key: '37"', label: '37"' },
          { key: '38"', label: '38"' },
          { key: '39"', label: '39"' },
          { key: '40"', label: '40"' },
          { key: '41"', label: '41"' },
          { key: '42"', label: '42"' },
          { key: 'N/A', label: 'N/A' },
          { key: 'Other', label: 'Other' },
        ],
      },
    ],
  },
  'tallsize-ca': {
    ...defaultConfig,
    styles: defaultStyles,
    copy: {},
    customVariantDropdownOptions: [
      {
        name: 'inseam',
        values: [
          { key: '32"', label: '32"' },
          { key: '33"', label: '33"' },
          { key: '34"', label: '34"' },
          { key: '35"', label: '35"' },
          { key: '36"', label: '36"' },
          { key: '37"', label: '37"' },
          { key: '38"', label: '38"' },
          { key: '39"', label: '39"' },
          { key: '40"', label: '40"' },
          { key: '41"', label: '41"' },
          { key: '42"', label: '42"' },
          { key: 'N/A', label: 'N/A' },
          { key: 'Other', label: 'Other' },
        ],
      },
    ],
  },
  brooklyncloth: {
    ...defaultConfig,
    styles: defaultStyles,
    copy: {},
  },
  'rollasjeans-au': {
    ...defaultConfig,
    styles: defaultStyles,
    copy: {},
  },
  'rollasjeans-us': {
    ...defaultConfig,
    styles: defaultStyles,
    copy: {},
  },
  'oschaslings-uk': {
    ...defaultConfig,
    styles: defaultStyles,
    copy: {},
  },
  'oschaslings-us': {
    ...defaultConfig,
    styles: defaultStyles,
    copy: {},
  },
  electricandrose: {
    ...defaultConfig,
    styles: defaultStyles,
    copy: {},
  },
  vitalydesign: {
    ...defaultConfig,
    styles: defaultStyles,
    copy: {},
  },
  portlandleather: {
    ...defaultConfig,
    chooseStockImagesOption: CHOOSE_STOCK_IMAGES_OPTIONS.PORTLAND_LEATHER,
    styles: defaultStyles,
    copy: {},
  },
  henkaa: {
    ...defaultConfig,
    styles: defaultStyles,
    copy: {},
  },
  joyfolie: {
    ...defaultConfig,
    styles: defaultStyles,
    copy: {},
  },
  tradlands: {
    ...defaultConfig,
    styles: defaultStyles,
    copy: {},
  },
  johnandmickie: {
    ...defaultConfig,
    styles: defaultStyles,
    copy: {},
  },
  galialahav: {
    ...defaultConfig,
    styles: defaultStyles,
    copy: {},
    customVariantDropdownOptions: [
      {
        name: 'Bust',
        values: ['N/A', ...dressMeasurements].map((measurement) => ({
          key: measurement,
          label: measurement,
        })),
      },
      {
        name: 'Waist',
        values: ['N/A', '22"', '22.5"', ...dressMeasurements].map((measurement) => ({
          key: measurement,
          label: measurement,
        })),
      },
      {
        name: 'Hips',
        values: [
          'N/A',
          ...dressMeasurements,
          '62.5"',
          '63"',
          '63.5"',
          '64"',
          '64.5"',
          '65"',
          '65.5"',
          '66"',
          '66.5"',
          '67"',
          'N/A',
        ].map((measurement) => ({ key: measurement, label: measurement })),
      },
      {
        name: 'Height With Shoes',
        values: [
          'N/A',
          '4\'0"',
          '4\'1"',
          '4\'2"',
          '4\'3"',
          '4\'4"',
          '4\'5"',
          '4\'6"',
          '4\'7"',
          '4\'8"',
          '4\'9"',
          '4\'10"',
          '4\'11"',
          '5\'0"',
          '5\'1"',
          '5\'2"',
          '5\'3"',
          '5\'4"',
          '5\'5"',
          '5\'6"',
          '5\'7"',
          '5\'8"',
          '5\'9"',
          '5\'10"',
          '5\'11"',
          '6\'0"',
          '6\'1"',
          '6\'2"',
          '6\'3"',
          '6\'4"',
          '6\'5"',
          '6\'6"',
          '6\'7"',
          '6\'8"',
          '6\'9"',
          '6\'10"',
          '6\'11"',
          '7\'0"',
        ].map((height) => ({ key: height, label: height })),
      },
      {
        name: 'Silhouette Type',
        values: [
          { key: 'n/a', label: 'N/A' },
          { key: 'ballgown', label: 'Ballgown' },
          { key: 'aline', label: 'A-line' },
          { key: 'modernelegant', label: 'Modern & Elegant' },
          { key: 'fitted', label: 'Fitted' },
          { key: 'mermaid', label: 'Mermaid' },
          { key: 'mini', label: 'Mini' },
          { key: 'laceembroidery', label: 'Lace & Embroidery' },
          { key: 'strapless', label: 'Strapless' },
          { key: 'fittoflare', label: 'Fit-to-Flare' },
          { key: 'trumpet', label: 'Trumpet' },
          { key: 'other', label: 'Other' },
        ],
      },
      {
        name: 'Train Style',
        values: [
          { key: 'n/a', label: 'N/A' },
          { key: 'none', label: 'No Train' },
          { key: '7/8', label: '7/8' },
          { key: 'sweep', label: 'Sweep' },
          { key: 'cathedral', label: 'Cathedral' },
          { key: 'mermaid', label: 'Mermaid' },
          { key: 'royal', label: 'Royal' },
          { key: 'supersixmeter', label: 'Super Six Meter' },
          { key: 'custom', label: 'Custom' },
          { key: 'other', label: 'Other' },
        ],
      },
    ],
    customItemDetailsInputFields: [
      {
        key: 'poNumber',
        type: 'text',
        label: 'P.O. # (ex. "LWD-123")',
        required: false,
        shouldDisplay: false,
        placeholder: 'Can be found in order confirmations or a sewn-in tag',
      },
      {
        key: 'customizations',
        type: 'textarea',
        label: 'Customizations',
        placeholder: 'Any customizations made on your item',
        required: false,
        shouldDisplay: true,
      },
    ],
    brandApprovalFields: ['poNumber', 'customizations'],
    tryOnReturnLink: '',
  },
  shopdyi: {
    ...defaultConfig,
    styles: defaultStyles,
    copy: {},
  },
  nettlestudios: {
    ...defaultConfig,
    styles: defaultStyles,
    copy: {},
  },
  shopeitheror: {
    ...defaultConfig,
    styles: defaultStyles,
    copy: {},
  },
  moskomoto: {
    ...defaultConfig,
    styles: defaultStyles,
    copy: {},
  },
  seniq: {
    ...defaultConfig,
    styles: defaultStyles,
    copy: {},
  },
  karinadresses: {
    ...defaultConfig,
    styles: defaultStyles,
    copy: {},
  },
  swoveralls: {
    ...defaultConfig,
    styles: defaultStyles,
    copy: {},
  },
  loveandgrowclothing: {
    ...defaultConfig,
    styles: defaultStyles,
    copy: {},
  },
  'charlotte-stone': {
    ...defaultConfig,
    styles: {
      ...defaultStyles,
      footerLogoHeight: '240px',
      footerLogoMaxWidth: '400px',
      footerLogoFilter: 'invert(1)',
      footerBackgroundColor: '#ffffff',
      footerFilter: 'invert(1)',
      footerTopBorderWidth: '0px',
      footerPadding: '0',
    },
    copy: {},
    logoPage: 'AboutBasePage',
    landingPageTransparentTopbarScrollThreshold: 0,
    landingPageLayoutOrder: [LANDING_PAGE_SECTION.HERO, LANDING_PAGE_SECTION.ABOUT],
  },
  sijo: {
    ...defaultConfig,
    styles: defaultStyles,
    copy: {},
  },
  unif: {
    ...defaultConfig,
    styles: defaultStyles,
    copy: {},
  },
  jennylemons: {
    ...defaultConfig,
    styles: defaultStyles,
    copy: {},
  },
  bandier: {
    ...defaultConfig,
    styles: defaultStyles,
    copy: {},
  },
  proudpoppyclothing: {
    ...defaultConfig,
    styles: defaultStyles,
    copy: {},
  },
  hyergoods: {
    ...defaultConfig,
    styles: defaultStyles,
    copy: {},
  },
  aceandjig: {
    ...defaultConfig,
    styles: defaultStyles,
    copy: {},
  },
  senitaathletics: {
    ...defaultConfig,
    styles: {
      ...defaultStyles,
      footerLogoHeight: '80px',
    },
    copy: {},
  },
  polejunkie: {
    ...defaultConfig,
    styles: defaultStyles,
    copy: {},
  },
  hudsonjeans: {
    ...defaultConfig,
    styles: {
      ...defaultStyles,
      footerLogoFilter: 'brightness(0)',
      footerBackgroundColor: '#ffffff',
      footerFilter: 'invert(1)',
      footerTopBorderWidth: '0px',
    },
    copy: {},
  },
  girlcrush: {
    ...defaultConfig,
    styles: defaultStyles,
    copy: {},
    // The name must match the sizeVariantOptionName in Contentful to force a size dropdown.
    // Doesn't need values since it's just used as a flag to show the size dropdown.
    customVariantDropdownOptions: [
      {
        name: 'taille',
        values: [],
      },
    ],
  },
  freedomravewear: {
    ...defaultConfig,
    styles: defaultStyles,
    copy: {},
  },
  ratboi: {
    ...defaultConfig,
    styles: defaultStyles,
    copy: {},
  },
  teddyblake: {
    ...defaultConfig,
    styles: defaultStyles,
    copy: {},
  },
  dixxon: {
    ...defaultConfig,
    styles: defaultStyles,
    copy: {},
  },
  plae: {
    ...defaultConfig,
    styles: defaultStyles,
    copy: {},
    yotpo: {
      linkToBrandLoyaltyPointsPage: 'https://plae.co/apps/help-center#hc-what-is-plae-rewards',
      yotpoPointsPerDollar: 100,
    },
  },
  alpakagear: {
    ...defaultConfig,
    styles: defaultStyles,
    copy: {},
  },
  dreamlandbabyco: {
    ...defaultConfig,
    styles: defaultStyles,
    copy: {},
  },
  'texas-standard': {
    ...defaultConfig,
    styles: defaultStyles,
    copy: {},
  },
  thenutr: {
    ...defaultConfig,
    styles: defaultStyles,
    copy: {},
  },
  timininous: {
    ...defaultConfig,
    styles: defaultStyles,
    copy: {},
  },
  robertgraham: {
    ...defaultConfig,
    styles: defaultStyles,
    copy: {},
  },
  mainstreetexchangeapparel: {
    ...defaultConfig,
    styles: defaultStyles,
    copy: {},
  },
  unionbay: {
    ...defaultConfig,
    styles: defaultStyles,
    copy: {},
    customVariantDropdownOptions: [
      {
        name: 'Decade',
        values: ['80s', '90s', '00s'].map((decade) => ({
          key: decade,
          label: decade,
        })),
      },
    ],
  },
  peachydogs: {
    ...defaultConfig,
    styles: defaultStyles,
    copy: {},
  },
  spell: {
    ...defaultConfig,
    chooseStockImagesOption: CHOOSE_STOCK_IMAGES_OPTIONS.SPELL,
    styles: {
      ...defaultStyles,
      // Move to contentful if more brands want skinny "add to bag" buttons
      ListingPage_buttonPadding: '3px',
    },
    copy: {},
  },
  'spell-usa': {
    ...defaultConfig,
    chooseStockImagesOption: CHOOSE_STOCK_IMAGES_OPTIONS.SPELL,
    styles: {
      ...defaultStyles,
      // Move to contentful if more brands want skinny "add to bag" buttons
      ListingPage_buttonPadding: '3px',
    },
    copy: {},
  },
  shopfavoritedaughter: {
    ...defaultConfig,
    styles: defaultStyles,
    copy: {},
  },
  itsmilla: {
    ...defaultConfig,
    styles: defaultStyles,
    copy: {},
  },
  gravelandgold: {
    ...defaultConfig,
    styles: defaultStyles,
    copy: {},
  },
  exoticathletica: {
    ...defaultConfig,
    styles: defaultStyles,
    copy: {},
  },
  'daniel-patrick': {
    ...defaultConfig,
    styles: defaultStyles,
    copy: {},
  },
  saguaroshowpads: {
    ...defaultConfig,
    styles: defaultStyles,
    copy: {},
  },
  'mymum-madeit': {
    ...defaultConfig,
    styles: defaultStyles,
    copy: {},
  },
  'vivobarefoot-ca': {
    ...defaultConfig,
    styles: defaultStyles,
    copy: {},
  },
  cozyearth: {
    ...defaultConfig,
    styles: defaultStyles,
    copy: {},
  },
  ingridandisabel: {
    ...defaultConfig,
    styles: defaultStyles,
    copy: {},
  },
  theandibrand: {
    ...defaultConfig,
    styles: defaultStyles,
    copy: {},
  },
  represent: {
    ...defaultConfig,
    showColorOnListingCard: true,
    styles: defaultStyles,
    copy: {},
  },
  urbancred: {
    ...defaultConfig,
    styles: defaultStyles,
    copy: {},
  },
  'theslowwardrobe-us': {
    ...defaultConfig,
    styles: defaultStyles,
    copy: {},
  },
  'theslowwardrobe-uk': {
    ...defaultConfig,
    styles: defaultStyles,
    copy: {},
  },
  livetheprocess: {
    ...defaultConfig,
    styles: defaultStyles,
    copy: {},
  },
  cariloha: {
    ...defaultConfig,
    styles: defaultStyles,
    copy: {},
  },
  cinnamonbabyau: {
    ...defaultConfig,
    styles: defaultStyles,
    copy: {},
  },
  kitteny: {
    ...defaultConfig,
    styles: {
      ...defaultStyles,
      // Move to contentful if more brands want skinny "add to bag" buttons
      ListingPage_buttonPadding: '3px',
    },
    copy: {},
  },
  pfflyers: {
    ...defaultConfig,
    styles: defaultStyles,
    copy: {},
  },
  jordancraig: {
    ...defaultConfig,
    styles: defaultStyles,
    copy: {},
  },
};

// Twitter handle is needed in meta tags (twitter:site). Start it with '@' character
const siteTwitterHandle = '@shoptreet';

// Instagram page is used in SEO schema (http://schema.org/Organization)
const siteInstagramPage = 'https://www.instagram.com/treetshop/';

// Facebook page is used in SEO schema (http://schema.org/Organization)
const siteFacebookPage = null;

const siteLinkedInPage = 'https://www.linkedin.com/company/treetshop';

// Facebook counts shares with app or page associated by this id
// Currently it is unset, but you can read more about fb:app_id from
// https://developers.facebook.com/docs/sharing/webmasters#basic
// You should create one to track social sharing in Facebook
const facebookAppId = null;

const maps = {
  googleMapsAPIKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,

  // The location search input can be configured to show default
  // searches when the user focuses on the input and hasn't yet typed
  // anything. This reduces typing and avoids too many Geolocation API
  // calls for common searches.
  search: {
    // When enabled, the first suggestion is "Current location" that
    // uses the browser Geolocation API to query the user's current
    // location.
    suggestCurrentLocation: process.env.REACT_APP_DEFAULT_SEARCHES_ENABLED === 'true',

    // Distance in meters for calculating the bounding box around the
    // current location.
    currentLocationBoundsDistance: 1000,

    // No defaults at present
    defaults: [],

    // Limit location autocomplete to a one or more countries
    // using ISO 3166 alpha 2 country codes separated by commas.
    // If you want to limit the autocomplete, uncomment this value:
    // countryLimit: ['AU'],
  },

  // When fuzzy locations are enabled, coordinates on maps are
  // obfuscated randomly around the actual location.
  //
  // NOTE: This only hides the locations in the UI level, the actual
  // coordinates are still accessible in the HTTP requests and the
  // Redux store.
  fuzzy: {
    enabled: false,

    // Amount of maximum offset in meters that is applied to obfuscate
    // the original coordinates. The actual value is random, but the
    // obfuscated coordinates are withing a circle that has the same
    // radius as the offset.
    offset: 500,

    // Default zoom level when showing a single circle on a Map. Should
    // be small enough so the whole circle fits in.
    defaultZoomLevel: 13,

    // Color of the circle on the Map component.
    circleColor: '#c0392b',
  },

  // Custom marker image to use in the Map component.
  //
  // NOTE: Not used if fuzzy locations are enabled.
  customMarker: {
    enabled: false,

    // Publicly accessible URL for the custom marker image.
    //
    // The easiest place is /public/static/icons/ folder, but then the
    // marker image is not available while developing through
    // localhost.
    url: encodeURI(`${canonicalRootURL}/static/icons/map-marker-32x32.png`),

    // Dimensions of the marker image.
    width: 32,
    height: 32,

    // Position to anchor the image in relation to the coordinates,
    anchorX: 16,
    anchorY: 32,
  },
};

// NOTE: only expose configuration that should be visible in the
// client side, don't add any server secrets in this file.
const config = {
  env,
  dev,
  test,
  locale,
  processAlias,
  bookingUnitType,
  enableAvailability,
  dayCountAvailableForBooking,
  i18n,
  sortSearchByDistance,
  listingMinimumPriceSubUnits,
  stripe: {
    defaultMCC,
    publishableKey: stripePublishableKey,
    supportedCountries: stripeCountryDetails,
  },
  canonicalRootURL,
  address: {
    addressCountry,
    addressRegion,
    postalCode,
    streetAddress,
  },
  shopConfigs,
  // siteTitle,
  siteFacebookPage,
  siteInstagramPage,
  siteTwitterHandle,
  siteLinkedInPage,
  facebookAppId,
  maps,
  custom,
};

export default config;
